<script setup lang="ts">
import { InvoiceBasesResource } from '@/types/invoice-row';
import { ShowTimeResource } from '@/types/show-time';
import { computed, nextTick, ref, watch } from 'vue';
import { exchangeValuesOfObject } from '@/util/globals';
import ContentList from '@/components/Content/ContentList.vue';
import InvoiceBasesCRUDModal from '@/components/Models/Invoices/InvoiceBasesCRUDModal.vue';
import AuditsSidebar from '@/components/Audits/AuditsSidebar.vue';
import { auditableInvoiceBasisFields, auditableInvoiceRowFields } from '@/helpers/auditHelper';
import { useEmitStore } from '@/store/EmitStore';
import { useInvoiceProductCategories } from '@/composables/invoices/use-invoice-product-categories';
import { useInvoiceProducts } from '@/composables/invoices/use-invoice-products';
import InvoiceBase from '@/components/Models/Invoices/InvoiceBase.vue';
import { storeToRefs } from 'pinia';
import { invoiceStore } from '@/store/invoice-store';
import { getGroup } from '@/services/api-group';
import { getRoute, openRoute } from '@/util/route';

type Props = {
  modelType: 'Group' | 'Invite';
  modelId: number;
  templateGroupId?: number | null;
  canEdit?: boolean;
  isRecurring?: boolean;
  showTimes?: ShowTimeResource[] | null;
};

const props = withDefaults(defineProps<Props>(), {
  templateGroupId: null,
  canEdit: false,
  isRecurring: false,
  showTimes: () => [],
});

const { categories, fetchInvoiceProductCategories } = useInvoiceProductCategories(props.templateGroupId);
const { products, fetchInvoiceProducts } = useInvoiceProducts(props.templateGroupId);

const { loading, list } = storeToRefs(invoiceStore());
const { fetchInvoice } = invoiceStore();

const data = await fetchInvoice(props.modelType, props.modelId, props.modelType === 'Group');

const invoiceBases = ref<Omit<InvoiceBasesResource, 'total' | 'users' | 'rows'>[]>(data ?? []);

const updateData = async () => {
  const data = await fetchInvoice(props.modelType, props.modelId);

  invoiceBases.value = data ?? [];
};

watch(
  list,
  async () => {
    await updateData();
  },
  { deep: true }
);

const fetchAll = async () => {
  loading.value = true;
  if (props.templateGroupId) {
    await fetchInvoiceProductCategories();
    await fetchInvoiceProducts();
  }
  loading.value = false;
};
fetchAll();

const showModal = ref(false);
const selectedInvoiceBasis = ref<InvoiceBasesResource | null>(null);
const selectInvoiceBasis = async (invoiceBasis?: InvoiceBasesResource) => {
  showModal.value = false;
  await nextTick();
  if (invoiceBasis) {
    selectedInvoiceBasis.value = invoiceBasis;
  } else {
    selectedInvoiceBasis.value = null;
  }
  showModal.value = true;
};

const invoiceBasisCreatedOrUpdated = (invoiceBasis: InvoiceBasesResource) => {
  invoiceBases.value = exchangeValuesOfObject(invoiceBasis, invoiceBases.value);
  fetchInvoice(props.modelType, props.modelId, true);
};

const removeInvoiceBasis = async (invoiceBasisId: number) => {
  const index = _.findIndex(invoiceBases.value, (b) => b.id === invoiceBasisId);
  index === -1 ? await updateData() : invoiceBases.value.splice(index, 1);
};

const auditsOpen = ref(false);

const actions = computed(() => {
  const array = [];
  if (props.canEdit && props.modelType === 'Invite') {
    const subItems = [
      {
        title: 'Audits',
        preIcon: 'fa-history',
        emphasized: true,
        action: async () => {
          auditsOpen.value = false;
          await nextTick();
          auditsOpen.value = true;
        },
      },
    ];

    if (props.templateGroupId) {
      subItems.push({
        title: 'Edit Templates',
        preIcon: 'fa-cog',
        action: async () => {
          const { data: group } = await getGroup(props.templateGroupId);

          openRoute(getRoute('groups.administrator', group.slug) + '#invoice-templates');
        },
      });
    }

    array.push({
      title: 'Actions',
      icon: 'fa-cog',
      emphasized: true,
      dropdown: subItems,
    });
  }

  array.push({
    title: 'Create',
    icon: 'fa-plus',
    type: 'primary',
    action: () => {
      selectInvoiceBasis({ id: null });
    },
  });
  return array;
});

useEmitStore().$subscribe((_, state) => {
  switch (state.item?.key) {
    case 'invoice-basis-added-tp-event-via-group-add-to-event': {
      updateData();
      break;
    }
    default:
      break;
  }
});

const getAllowedAuditSidebarFields = () => {
  return [
    { model: 'App\\InvoiceBasis', fields: auditableInvoiceBasisFields() },
    { model: 'App\\InvoiceRow', fields: auditableInvoiceRowFields() },
  ];
};
</script>

<template>
  <ContentList
    :loading="loading"
    :title="modelType === 'Group' ? 'Invoice Templates' : 'Invoice Bases'"
    :under-title="''"
    :with-back-button="modelType === 'Group'"
    :pre-icon="modelType === 'Group' ? 'fa-list' : 'fa-money'"
    content-slot-classes="pt-edge"
    :empty-message="invoiceBases.length === 0 && !loading ? 'No Invoices Has Been Added' : null"
    :empty-action="
      () => {
        selectInvoiceBasis({ id: null });
      }
    "
    :actions="actions">
    <InvoiceBase
      v-for="invoiceBase in invoiceBases"
      :key="invoiceBase.id"
      :is-single-invoice="modelType === 'Invite' && invoiceBases.length === 1"
      :can-edit="canEdit"
      :is-template="modelType === 'Group'"
      :show-times="showTimes"
      :categories="categories"
      :model-id="modelId"
      :model="modelType"
      :products="products"
      :template-group-id="templateGroupId"
      :is-display="false"
      :invoice-base="invoiceBase"
      @update:invoice-base="invoiceBases = exchangeValuesOfObject($event, invoiceBases)"
      @edit="selectInvoiceBasis(invoiceBase)" />
  </ContentList>

  <InvoiceBasesCRUDModal
    v-if="showModal"
    :init-invoice-basis="selectedInvoiceBasis"
    :categories="categories"
    :is-template="modelType === 'Group'"
    :template-group-id="templateGroupId"
    :model-id="modelId"
    :model="modelType"
    :is-recurring="isRecurring"
    @closed="showModal = false"
    @deleted="removeInvoiceBasis"
    @updated="invoiceBasisCreatedOrUpdated"
    @created="invoiceBasisCreatedOrUpdated" />

  <AuditsSidebar
    v-if="auditsOpen && modelType === 'Invite'"
    key="Audits for Invoices"
    open-on-create
    :can-filter-models="false"
    :url="'/api/audits/invites/' + modelId"
    :queryable-parameters="[
      { name: 'InvoiceBasis', id: 'App\\InvoiceBasis' },
      { name: 'InvoiceRow', id: 'App\\InvoiceRow' },
    ]"
    :allowed-fields="getAllowedAuditSidebarFields()"
    title="Audits for Invoices">
  </AuditsSidebar>
</template>
