<script lang="ts" setup>
import { computed, nextTick, ref } from 'vue';
import { getIndexFromArrayBasedOnId, getItemFromArrayBasedOnId, getKey } from '@/util/globals';
import { useDebounceFn, useEventListener } from '@vueuse/core';
import { useEmitStore } from '@/store/EmitStore';
import VList, { Items } from '@/components/VList.vue';
import FloatingWindowContainer from '@/components/Inputs/Components/FloatingWindowContainer.vue';
import VButton from '@/components/Inputs/VButton.vue';
import VDropdown from '@/components/Inputs/Dropdown/VDropdown.vue';
import CheckBox from '@/components/Icons/CheckBox.vue';
import VMultiselect from '@/components/Inputs/VMultiselect.vue';
import NumberInput from '@/components/Inputs/NumberInput.vue';
import VSelect from '@/components/Inputs/VSelect.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import SelectOneOfManyButtons from '@/components/Inputs/Components/SelectOneOfManyButtons.vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import SettingCheck from '@/components/Inputs/Components/SettingCheck.vue';
import TableButtonSelector from '@/components/Tables/Headers/TableButtonSelector.vue';

type Props = {
  fieldFilters?: object[];
  sections?: object[];
  preferenceFilters?: object[];
  preferences?: object[];
  loading?: boolean;
  closeOnScroll?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  closeOnScroll: true,
  preferences: () => [],
  preferenceFilters: () => [],
  fieldFilters: () => [],
  sections: () => [],
});

const emits = defineEmits<{
  (e: 'update:fieldFilters', value: number | string | object | null): void;
  (e: 'update:preferenceFilters', value: number | string | object | null): void;
  (e: 'search'): void;
  (e: 'update:loading'): void;
}>();

const toggleFilter = (field, section) => {
  if (props.fieldFilters.map((f) => f.id).includes(field.id)) {
    removeFilter(field);
  } else {
    addFilter(field, section);
  }
  window.dispatchEvent(new Event('resize'));
  nextTick(() => {
    window.dispatchEvent(new Event('resize'));
  });
};

const { rootEmit } = useEmitStore();
const togglePreference = (preference, addIfDoesntExist = true, alwaysSearch = false) => {
  const localPreferenceFilters = [...props.preferenceFilters];
  const index = getIndexFromArrayBasedOnId(preference.id, localPreferenceFilters);
  if (index === -1) {
    if (addIfDoesntExist) {
      rootEmit('close-all-drop-downs');
      const operators = getOperators(preference.id);
      localPreferenceFilters.push({
        id: preference.id,
        displayTitle: preference.name,
        type: 'preference',
        operator: operators.length > 0 ? operators[0].id : null,
        preference: preference,
        selectedIds: [],
      });
      setTimeout(() => {
        const element = document.getElementById('preference_' + preference.id);
        if (element) {
          element.click();
        }
      }, 50);
    }
  } else {
    localPreferenceFilters.splice(index, 1);
  }
  emits('update:preferenceFilters', localPreferenceFilters);
  if (index > -1 || alwaysSearch) emits('search');

  window.dispatchEvent(new Event('resize'));
  nextTick(() => {
    window.dispatchEvent(new Event('resize'));
  });
};

const addFilter = (field, section) => {
  const localFieldFilters = [...props.fieldFilters];
  const index = getIndexFromArrayBasedOnId(field.id, localFieldFilters);
  if (index === -1) {
    rootEmit('close-all-drop-downs');
    let value = null;
    let operator = '=';
    switch (field.component) {
      case 'field-list': {
        value = [];
        break;
      }
      case 'field-toggle': {
        value = 'true';
        break;
      }
      case 'field-text': {
        operator = 'contains';
        break;
      }
    }

    localFieldFilters.push({
      id: field.id,
      displayTitle: field.name,
      component: field.component,
      sectionName: section?.name,
      fieldType: getKey(field, 'type', null),
      type: 'field',
      value: value,
      operator: operator,
    });

    setTimeout(() => {
      const element = document.getElementById('field_filter_' + field.id);
      if (element) {
        element.click();
      }
    }, 50);
  }
  emits('update:fieldFilters', localFieldFilters);
  readyToSearch();
};
const clearAllSearches = () => {
  emits('update:preferenceFilters', []);
  emits('update:fieldFilters', []);
  emits('search');
  pageY.value = null;
  pageX.value = null;
  window.dispatchEvent(new Event('resize'));
  nextTick(() => {
    window.dispatchEvent(new Event('resize'));
  });
};

const removeFilter = (filter) => {
  const localFieldFilters = [...props.fieldFilters];
  const index = getIndexFromArrayBasedOnId(filter.id, localFieldFilters);
  if (index === -1) return;
  localFieldFilters.splice(index, 1);
  emits('update:fieldFilters', localFieldFilters);
  emits('search');
};

const getOperators = (component) => {
  let operators = [
    { id: '=', name: 'Equals' },
    { id: '>=', name: 'Before or same' },
    { id: '<=', name: 'After or same' },
  ];
  switch (component) {
    case 'field-text': {
      operators = operators.concat({
        id: 'contains',
        name: 'Contains',
      });
      break;
    }
    case 'field-date': {
      return [
        { id: '=', name: 'Is same year' },
        { id: '>', name: 'After year' },
        { id: '<', name: 'Before year' },
        { id: 'between', name: 'Between' },
      ];
    }
    case 'field-number': {
      return [
        { id: '=', name: 'Equals' },
        { id: '>', name: 'Grater than' },
        { id: '<', name: 'Smaller than' },
        { id: 'between', name: 'Between' },
      ];
    }
    case 'assigned_work_load': {
      return [
        { id: '>', name: 'More than' },
        { id: '=', name: 'Equals' },
        { id: '<', name: 'Less than' },
        // { id: 'between', name: 'Between' },
      ];
    }
  }
  return operators;
};

const readyToSearch = useDebounceFn(async () => {
  emits('update:loading', true);
  emits('search');
}, 250);

const pageX = ref(null);
const pageY = ref(null);

const updateFilterValue = (filter, key, value) => {
  const localFieldFilters = [...props.fieldFilters];
  const index = getIndexFromArrayBasedOnId(filter.id, localFieldFilters);
  if (index === -1) return;
  localFieldFilters[index][key] = value;
  emits('update:fieldFilters', localFieldFilters);
  emits('search');
};

const updatePreferenceValue = (preference, key, value, addIfDoesntExist = false) => {
  const localPreferenceFilters = [...props.preferenceFilters];
  const index = getIndexFromArrayBasedOnId(preference.id, localPreferenceFilters);
  if (index === -1) {
    if (addIfDoesntExist) {
      togglePreference(preference);
      setTimeout(() => {
        updatePreferenceValue(preference, key, value);
      }, 100);
      return;
    }
    return;
  }
  localPreferenceFilters[index][key] = value;
  emits('update:preferenceFilters', localPreferenceFilters);
  emits('search');
};
const target = ref<HTMLDivElement | null>(null);

const dropdownOpen = ref(false);

if (props.closeOnScroll) {
  useEventListener(
    'wheel',
    (e) => {
      if (e.target?.id === 'backdrop__floating-window') {
        pageX.value = null;
        pageY.value = null;
        return;
      }

      if (target.value || dropdownOpen.value === true) return;
      pageX.value = null;
      pageY.value = null;
    },
    { passive: true }
  );
}

const allItems = (close: () => void) => {
  const array = [] as Items[];

  const nonQuickFilters = props.preferences.filter(
    (item) => !getKey(item, 'isQuickFilter', false) || getKey(item, 'isRegularFilter', true)
  );
  if (nonQuickFilters.length) {
    array.push({
      title: 'Filters',
      type: 'header',
    });
    nonQuickFilters.forEach((item) => {
      array.push({
        selected: props.preferenceFilters.map((f) => f.id).includes(item.id),
        title: item.name,
        value: item.id,
        action: () => {
          togglePreference(item);
        },
      });
    });
    array.push({
      type: 'divider',
    });
  }

  if (props.sections.length) {
    props.sections.forEach((section) => {
      if (section.fields.length === 0) return null;
      array.push({
        title: section.name,
        type: 'header',
      });
      section.fields.forEach((field) => {
        array.push({
          selected: props.fieldFilters.map((f) => f.id).includes(field.id),
          title: field.name,
          value: field.id,
          action: () => {
            toggleFilter(field);
          },
        });
      });
      array.push({
        type: 'divider',
      });
    });
  }

  return array.filter((i) => i !== null);
};

const setFocusForField = ref(false);
const openFilterMenu = (event) => {
  pageX.value = null;
  pageY.value = null;
  setFocusForField.value = false;
  nextTick(() => {
    pageX.value = event.target.getBoundingClientRect().x - 200;
    pageY.value = event.target.getBoundingClientRect().y + 25;
  });
  setTimeout(() => {
    setFocusForField.value = true;
  }, 1000);
};

const quickPreferenceFilters = computed(() => {
  return props.preferences.filter((p) => getKey(p, 'isQuickFilter', false));
});

const getOptionsForAssignedUserFilter = (preference) => {
  const filteringByModel = props.preferenceFilters.filter((p) =>
    ['task_assigned_group', 'task_assigned_context'].includes(p.id)
  );
  if (filteringByModel.length === 0) {
    return getKey(preference.preference, 'options', []).flatMap((o) => {
      return [{ name: o.label, type: 'header' }].concat(o.options);
    });
  }
  const filterType = filteringByModel[0];

  if (!filterType) return [];

  let formattedModelId = null;
  if (filterType.id === 'task_assigned_group') {
    formattedModelId = filterType.value;
  } else if (filterType.id === 'task_assigned_context') {
    formattedModelId = filterType.value;
  }

  if (!formattedModelId) return [];
  return getKey(
    getKey(preference.preference, 'options', []).filter((option) => option.formattedModelId === formattedModelId)[0],
    'options',
    []
  );
};

const getQuickFilterLabel = (quickFilter) => {
  switch (quickFilter.id) {
    case 'task_my_tasks': {
      return 'Only My Tasks';
    }
    case 'performance_me_project_leader': {
      return 'My Performances';
    }
    case 'tasks_event_tasks': {
      return 'Show Event Tasks';
    }
    case 'tasks_child_tasks': {
      return 'Show Team Tasks';
    }
  }
  return null;
};

const getFilterValueBetween = (filter: (number | string)[], index: number) => (filter ? filter[index] : null);
const setFilterValueBetween = (filter: number[] | null, index: number, newValue: number | string) => {
  let f = filter?.value ?? [null, null];
  f[index] = newValue;
  updateFilterValue(filter, 'value', f);
};
const getPrefrenceValueBetween = (filter: (number | string)[], index: number) =>
  filter ? Math.abs((filter[index] ?? 0) / 60).toFixed(0) : Math.abs(0 / 60).toFixed(0);
const setPrefrenceValueBetween = (filter: number[] | null, index: number, newValue: number | string) => {
  const array = [null, null];
  if (typeof filter?.value === 'object') {
    array[0] = filter?.value[0];
    array[1] = filter?.value[1];
  }
  array[index] = newValue;
  updatePreferenceValue(filter, 'value', array);
};
</script>

<template>
  <div class="flex items-center gap-edge">
    <VButton
      icon="fa-filter"
      size="sm"
      post-icon="fa-chevron-down"
      :title="`Filters: ${fieldFilters.length + preferenceFilters.length}`"
      @click="openFilterMenu($event)" />

    <VButton
      v-if="fieldFilters.length + preferenceFilters.length > 0 && !pageX && !pageY"
      type="warning"
      title="Clear"
      size="xs"
      tool-tip-text="Clear all filters"
      @click="clearAllSearches()" />
  </div>

  <FloatingWindowContainer
    v-if="pageX && pageY"
    :page-x="pageX"
    :page-y="pageY"
    @closed="[(pageX = null), (pageY = null)]">
    <div class="flex min-w-[500px] max-w-[50vw] flex-col gap-edge rounded border bg-content p-edge">
      <div class="mb-edge flex items-center justify-between gap-edge">
        <h3 class="font-headers text">
          Selected Filters {{ fieldFilters.length + preferenceFilters.length }} /
          {{ allItems().filter((i) => !['header', 'divider'].includes(i.type)).length }}
        </h3>
        <VButton
          size="xs"
          type="warning"
          :disabled="fieldFilters.length + preferenceFilters.length === 0"
          title="Clear"
          icon="fa-times"
          @click="clearAllSearches()" />
      </div>
      <div
        v-if="quickPreferenceFilters.length > 0"
        class="mb-edge flex flex-col gap-edge">
        <div
          v-for="quickFilter in quickPreferenceFilters.filter((q) =>
            ['task_assigned_group', 'task_assigned_context'].includes(q.id)
          )"
          :key="quickFilter.id">
          <div v-if="quickFilter.id === 'task_assigned_group'">
            <InputLabel
              super-text
              label="Group" />
            <TableButtonSelector
              class="[&_.selected-text-container]:truncate [&_button]:w-full"
              icon="fa-group fa-regular"
              with-filtering
              :selected-text="
                getItemFromArrayBasedOnId(
                  getItemFromArrayBasedOnId(quickFilter.id, preferenceFilters, { value: null }).value,
                  quickFilter.options,
                  { name: 'All' }
                ).name
              "
              :model-value="false"
              :options="
                [{ id: null, name: 'All' }].concat(quickFilter.options).map((i) => {
                  return {
                    title: i.name,
                    postIcon:
                      getItemFromArrayBasedOnId(quickFilter.id, preferenceFilters, { value: null }).value === i.id
                        ? 'fa-check'
                        : '',
                    action: (close) => {
                      i.id !== null
                        ? updatePreferenceValue(quickFilter, 'value', i.id, true)
                        : togglePreference(quickFilter, false),
                        close();
                    },
                  };
                })
              " />
          </div>
          <div v-if="quickFilter.id === 'task_assigned_context'">
            <InputLabel
              super-text
              label="Group" />
            <TableButtonSelector
              icon="fa-group fa-regular"
              button-text="Group:"
              with-filtering
              class="[&_.selected-text-container]:truncate [&_button]:w-full"
              :selected-text="
                getItemFromArrayBasedOnId(
                  getItemFromArrayBasedOnId(quickFilter.id, preferenceFilters, { value: null }).value,
                  quickFilter.options.flatMap((o) => o.options),
                  { name: 'All' }
                ).name
              "
              :model-value="false"
              :options="
                [
                  {
                    title: 'All',
                    postIcon:
                      getItemFromArrayBasedOnId(quickFilter.id, preferenceFilters, { value: null }).value === null
                        ? 'fa-check'
                        : '',
                    action: (close) => {
                      togglePreference(quickFilter, false);
                      close();
                    },
                  },
                ].concat(
                  quickFilter.options.flatMap((o) => {
                    return [
                      {
                        type: 'header',
                        title: o.label,
                      },
                    ].concat(
                      o.options.map((item) => {
                        return {
                          title: item.name,
                          postIcon:
                            getItemFromArrayBasedOnId(quickFilter.id, preferenceFilters, { value: null }).value ===
                            item.id
                              ? 'fa-check'
                              : '',
                          action: (close) => {
                            item.id !== null
                              ? updatePreferenceValue(quickFilter, 'value', item.id, true)
                              : togglePreference(quickFilter, false),
                              close();
                          },
                        };
                      })
                    );
                  })
                )
              " />
          </div>
        </div>
        <div>
          <InputLabel
            label="Quick Filters"
            super-text />
          <div class="flex flex-col gap-edge-1/2">
            <div v-for="quickFilter in quickPreferenceFilters">
              <SettingCheck
                v-if="
                  ['task_my_tasks', 'tasks_event_tasks', 'tasks_child_tasks', 'performance_me_project_leader'].includes(
                    quickFilter.id
                  )
                "
                :model-value="getItemFromArrayBasedOnId(quickFilter.id, preferenceFilters) !== null"
                :color="getItemFromArrayBasedOnId(quickFilter.id, preferenceFilters) ? 'success' : 'transparent'"
                :label="getQuickFilterLabel(quickFilter)"
                @click="togglePreference(quickFilter, true, true)" />

              <SettingCheck
                v-else-if="quickFilter.id === 'task_completed_tasks'"
                :model-value="getItemFromArrayBasedOnId(quickFilter.id, preferenceFilters, { value: null }).value === 0"
                :color="getItemFromArrayBasedOnId(quickFilter.id, preferenceFilters) ? 'success' : 'transparent'"
                label="Show Completed"
                @click="
                  getItemFromArrayBasedOnId(quickFilter.id, preferenceFilters, { value: null }).value === 0
                    ? togglePreference(quickFilter, true, true)
                    : updatePreferenceValue(quickFilter, 'value', 0, true)
                " />
              <SettingCheck
                v-else-if="quickFilter.id === 'task_due_date'"
                :model-value="
                  getItemFromArrayBasedOnId(quickFilter.id, preferenceFilters, { value: null }).value === 'today'
                "
                :color="getItemFromArrayBasedOnId(quickFilter.id, preferenceFilters) ? 'success' : 'transparent'"
                label="Due Today"
                @click="
                  getItemFromArrayBasedOnId(quickFilter.id, preferenceFilters, { value: null }).value === 'today'
                    ? togglePreference(quickFilter, true, true)
                    : updatePreferenceValue(quickFilter, 'value', 'today', true)
                " />
            </div>
          </div>
        </div>
      </div>
      <div
        v-for="preference in preferenceFilters.filter(
          (p) =>
            ![
              'task_assigned_group',
              'task_my_tasks',
              'tasks_event_tasks',
              'tasks_child_tasks',
              'task_assigned_context',
              'performance_me_project_leader',
            ].includes(p.id)
        )"
        :key="preference.id"
        class="grid grid-cols-[auto_50px] items-center space-y-edge">
        <div>
          <InputLabel
            super-text
            :label="preference.preference.name" />
          <div
            v-if="
              preference.preference.id === 'festival_section' ||
              preference.preference.id === 'assigned_festival_sections'
            ">
            <VMultiselect
              v-model="preference.selectedIds"
              :close-on-select="false"
              :set-focus="setFocusForField"
              placeholder="Select Sections to Show"
              :options="preference.preference.options"
              :have-max-width="false"
              :close-on-scroll="false"
              @dropdown-closed="dropdownOpen = false"
              @dropdown-opened="dropdownOpen = true"
              @update:model-value="updatePreferenceValue(preference, 'selectedIds', preference.selectedIds)" />
          </div>
          <div v-else-if="preference.preference.id === 'time_slots'">
            <VMultiselect
              v-model="preference.selectedIds"
              :close-on-select="false"
              :have-max-width="false"
              :set-focus="setFocusForField"
              placeholder="Select Time Slots to Show"
              :options="preference.preference.options"
              :close-on-scroll="false"
              @dropdown-closed="dropdownOpen = false"
              @dropdown-opened="dropdownOpen = true"
              @update:model-value="updatePreferenceValue(preference, 'selectedIds', preference.selectedIds)" />
          </div>
          <div
            v-else-if="preference.preference.id === 'assigned_work_load'"
            class="grid items-center gap-edge"
            :class="preference.operator === 'between' ? 'grid-cols-[80px_1fr_1fr_50px]' : 'grid-cols-[120px_1fr_50px]'">
            <VSelect
              v-model="preference.operator"
              :set-focus="setFocusForField"
              :options="getOperators(preference.id)"
              @update:model-value="updatePreferenceValue(preference, 'operator', $event)" />
            <NumberInput
              v-if="preference.operator !== 'between'"
              :model-value="Math.abs((preference.value ?? 0) / 60).toFixed(0)"
              size="block"
              placeholder="Number of hours"
              :data-list-values="[1, 2, 4, 8, 12, 18, 24, 36, 48]"
              can-edit
              :format-data-list-option="
                (num) => {
                  return num + ' hours';
                }
              "
              @update:model-value="preference.value = $event * 60"
              @blur="updatePreferenceValue(preference, 'value', $event * 60)"
              @keydown.enter="updatePreferenceValue(preference, 'value', preference.value * 60)" />
            <div
              v-if="preference.operator === 'between'"
              class="flex items-center gap-edge-1/4">
              <NumberInput
                :model-value="getPrefrenceValueBetween(preference.value, 0)"
                size="block"
                placeholder="Number of hours"
                :data-list-values="[1, 2, 4, 8, 12, 18, 24, 36, 48]"
                can-edit
                :format-data-list-option="
                  (num) => {
                    return num + ' hours';
                  }
                "
                @update:model-value="setPrefrenceValueBetween(preference, 0, $event * 60)" />
              h
            </div>
            <div
              v-if="preference.operator === 'between'"
              class="flex items-center gap-edge-1/4">
              <NumberInput
                :model-value="getPrefrenceValueBetween(preference.value, 1)"
                size="block"
                placeholder="Number of hours"
                :data-list-values="[1, 2, 4, 8, 12, 18, 24, 36, 48]"
                can-edit
                :format-data-list-option="
                  (num) => {
                    return num + ' hours';
                  }
                "
                @update:model-value="setPrefrenceValueBetween(preference, 1, $event * 60)" />
              h
            </div>
            <div v-if="preference.operator !== 'between'">hours</div>
          </div>
          <div v-else-if="preference.preference.id === 'phone'">
            <VSelect
              :model-value="getKey(preference, 'value', null)"
              :close-on-select="false"
              :set-focus="setFocusForField"
              placeholder="Select Search"
              nullable
              nullable-display-text=""
              :options="preference.preference.options"
              :have-max-width="false"
              @dropdown-closed="dropdownOpen = false"
              @dropdown-opened="dropdownOpen = true"
              @update:model-value="[
                updatePreferenceValue(preference, 'value', preference.id),
                (preference.value = $event),
              ]" />
          </div>
          <div v-else-if="preference.preference.id === 'performance_type_id'">
            <VSelect
              :model-value="getKey(preference, 'value', null)"
              :close-on-select="false"
              :set-focus="setFocusForField"
              placeholder="Select Type"
              nullable
              nullable-display-text="All"
              :options="preference.preference.options"
              :have-max-width="false"
              @dropdown-closed="dropdownOpen = false"
              @dropdown-opened="dropdownOpen = true"
              @update:model-value="[updatePreferenceValue(preference, 'value', $event), (preference.value = $event)]" />
          </div>
          <div v-else-if="preference.preference.id === 'confirmed'">
            <SelectOneOfManyButtons
              :model-value="getKey(preference, 'value', null)"
              :values="[
                { id: 'true', name: 'Yes' },
                { id: 'false', name: 'No' },
              ]"
              @update:model-value="[updatePreferenceValue(preference, 'value', $event), (preference.value = $event)]" />
          </div>

          <div v-else-if="preference.preference.id === 'status'">
            <TableButtonSelector
              :selected-text="
                getItemFromArrayBasedOnId(
                  getKey(preference, 'value', null),
                  [
                    { id: 'pending', name: 'Pending' },
                    { id: 'accepted', name: 'Accepted' },
                    { id: 'declined', name: 'Declined' },
                    { id: null, name: 'All' },
                  ],
                  { name: 'All' }
                ).name
              "
              :options="
                [
                  { id: null, title: 'All' },
                  { id: 'pending', title: 'Pending' },
                  { id: 'accepted', title: 'Accepted' },
                  { id: 'declined', title: 'Declined' },
                ].map((i) => {
                  return {
                    title: i.title,
                    postIcon: getKey(preference, 'value', null) === i.id ? 'fa-check' : '',
                    action: (close) => {
                      updatePreferenceValue(preference, 'value', i.id);
                      preference.value = i.id;
                      close();
                    },
                  };
                })
              " />
          </div>
          <div v-else-if="preference.preference.id === 'performance_venues'">
            <VMultiselect
              :model-value="getKey(preference, 'value', null)"
              :close-on-select="false"
              :set-focus="setFocusForField"
              placeholder="Select Venue"
              :options="preference.preference.options"
              :have-max-width="false"
              @dropdown-closed="dropdownOpen = false"
              @dropdown-opened="dropdownOpen = true"
              @update:model-value="[updatePreferenceValue(preference, 'value', $event), (preference.value = $event)]" />
          </div>
          <div v-else-if="preference.preference.id === 'performance_rooms'">
            <VMultiselect
              :model-value="getKey(preference, 'value', null)"
              :close-on-select="false"
              :set-focus="setFocusForField"
              placeholder="Select Room"
              groups
              :options="preference.preference.options"
              :have-max-width="false"
              @dropdown-closed="dropdownOpen = false"
              @dropdown-opened="dropdownOpen = true"
              @update:model-value="[updatePreferenceValue(preference, 'value', $event), (preference.value = $event)]" />
          </div>
          <div v-else-if="preference.preference.id === 'task_due_date'">
            <TableButtonSelector
              :selected-text="preference.value"
              icon="fa-tag fa-regular"
              :model-value="false"
              :options="
                [
                  { title: 'Past', value: 'past' },
                  { title: 'Past 7 days', value: 'past 7 days' },
                  { title: 'Today', value: 'today' },
                  { title: 'Next 7 days', value: 'next 7 days' },
                  { title: 'Future', value: 'future' },
                  { title: 'No Due Date', value: 'N/A' },
                ].map((i) => {
                  return {
                    title: i.title,
                    postIcon: preference.value === i.value ? 'fa-check' : '',
                    action: (close) => {
                      updatePreferenceValue(preference, 'value', i.value);
                      preference.value = i.value;
                      close();
                    },
                  };
                })
              " />
          </div>
          <div v-else-if="preference.preference.id === 'task_completed_tasks'">
            <TableButtonSelector
              :selected-text="preference.value === 0 ? 'All' : preference.value === 1 ? 'Complete' : 'Incomplete'"
              icon="fa-tag fa-regular"
              :model-value="false"
              :options="
                [
                  { title: 'All', value: 0 },
                  { title: 'Complete', value: 1 },
                  { title: 'Incomplete', value: 2 },
                ].map((i) => {
                  return {
                    title: i.title,
                    postIcon: preference.value === i.value ? 'fa-check' : '',
                    action: (close) => {
                      updatePreferenceValue(preference, 'value', i.value);
                      preference.value = i.value;
                      close();
                    },
                  };
                })
              " />
          </div>

          <div v-else-if="preference.preference.id === 'task_assigned_group_user'">
            <div
              v-if="
                preferenceFilters.filter((p) => ['tasks_event_tasks', 'task_my_tasks'].includes(p.id)).length === 0
              ">
              <TableButtonSelector
                class="[&_.selected-text-container]:truncate [&_button]:w-full"
                icon="fa-user fa-regular"
                :selected-text="
                  getItemFromArrayBasedOnId(
                    getKey(preference, 'value', null),
                    getOptionsForAssignedUserFilter(preference),
                    { name: 'All' }
                  ).name
                "
                with-filtering
                :model-value="false"
                :options="
                  [{ id: null, name: 'All' }].concat(getOptionsForAssignedUserFilter(preference)).map((i) => {
                    return {
                      title: i.name,
                      type: getKey(i, 'type'),
                      postIcon: getKey(preference, 'value', null) === i.id ? 'fa-check' : '',
                      action: (close) => {
                        updatePreferenceValue(preference, 'value', i.id), (preference.value = i.id);
                        close();
                      },
                    };
                  })
                " />
            </div>
            <div v-else>Already filtering on yourself</div>
          </div>
          <div v-else-if="preference.preference.id === 'performance_project_leader'">
            <div v-if="preferenceFilters.filter((p) => ['performance_me_project_leader'].includes(p.id)).length === 0">
              <TableButtonSelector
                class="[&_.selected-text-container]:truncate [&_button]:w-full"
                icon="fa-user fa-regular"
                :selected-text="
                  getItemFromArrayBasedOnId(
                    getKey(preference, 'value', null),
                    getKey(preference.preference, 'options', []),
                    { name: 'All' }
                  ).name
                "
                with-filtering
                :model-value="false"
                :options="
                  [{ id: null, name: 'All' }].concat(getKey(preference.preference, 'options', [])).map((i) => {
                    return {
                      title: i.name,
                      type: getKey(i, 'type'),
                      postIcon: getKey(preference, 'value', null) === i.id ? 'fa-check' : '',
                      action: (close) => {
                        updatePreferenceValue(preference, 'value', i.id), (preference.value = i.id);
                        close();
                      },
                    };
                  })
                " />
            </div>
            <div v-else>Already filtering on yourself</div>
          </div>
          <div v-else>
            <div class="italic text-soft">Not Implements</div>
          </div>
        </div>
        <div>
          <VButton
            type="warning"
            icon="fa-trash fa-regular"
            @click="togglePreference(preference)" />
        </div>
      </div>

      <div
        v-for="filter in fieldFilters"
        :key="filter.id"
        class="flex items-end gap-edge">
        <div class="flex-1">
          <InputLabel
            super-text
            :label="filter.displayTitle" />
          <div>
            <div v-if="filter.component === 'field-list'">
              <VMultiselect
                v-model="filter.value"
                :have-max-width="false"
                :set-focus="setFocusForField"
                :options="
                  getItemFromArrayBasedOnId(
                    filter.id,
                    sections.flatMap((s) => s.fields),
                    { options: [] }
                  ).options
                "
                :close-on-scroll="false"
                @dropdown-closed="dropdownOpen = false"
                @dropdown-opened="dropdownOpen = true"
                @update:model-value="updateFilterValue(filter, 'value', $event)" />
            </div>
            <div v-else-if="filter.component === 'field-toggle'">
              <SelectOneOfManyButtons
                v-model="filter.value"
                :values="[
                  { id: 'true', name: 'Yes' },
                  { id: 'false', name: 'No' },
                ]"
                @update:model-value="updateFilterValue(filter, 'value', $event)" />
            </div>
            <div v-else-if="filter.component === 'field-text'">
              <TextInput
                v-model="filter.value"
                placeholder="Type text and press enter"
                :set-focus="setFocusForField"
                @blur="updateFilterValue(filter, 'value', $event)"
                @keydown.enter="updateFilterValue(filter, 'value', filter.value)" />
            </div>
            <div v-else-if="filter.component === 'field-date'">
              <div
                class="grid gap-edge"
                :class="filter.operator === 'between' ? 'grid-cols-3' : 'grid-cols-2'">
                <VSelect
                  v-model="filter.operator"
                  :set-focus="setFocusForField"
                  :options="getOperators(filter.component)"
                  @update:model-value="[
                    updateFilterValue(filter, 'operator', $event),
                    updateFilterValue(filter, 'value', null),
                  ]" />
                <NumberInput
                  v-if="filter.operator !== 'between'"
                  v-model="filter.value"
                  size="block"
                  placeholder="YYYY"
                  can-edit
                  @blur="updateFilterValue(filter, 'value', $event)"
                  @keydown.enter="updateFilterValue(filter, 'value', filter.value)" />
                <NumberInput
                  v-if="filter.operator === 'between'"
                  :model-value="getFilterValueBetween(filter.value, 0)"
                  size="block"
                  placeholder="YYYY"
                  can-edit
                  @blur="setFilterValueBetween(filter, 0, $event)"
                  @keydown.enter="setFilterValueBetween()" />
                <NumberInput
                  v-if="filter.operator === 'between'"
                  :model-value="getFilterValueBetween(filter.value, 1)"
                  size="block"
                  placeholder="YYYY"
                  can-edit
                  @blur="setFilterValueBetween(filter, 1, $event)"
                  @keydown.enter="setFilterValueBetween(filter, 0, $event)" />
              </div>
            </div>
            <div v-else-if="filter.component === 'field-number'">
              <div
                class="grid gap-edge"
                :class="filter.operator === 'between' ? 'grid-cols-3' : 'grid-cols-2'">
                <VSelect
                  v-model="filter.operator"
                  :set-focus="setFocusForField"
                  :options="getOperators(filter.component)"
                  @update:model-value="updateFilterValue(filter, 'operator', $event)" />
                <NumberInput
                  v-if="filter.operator !== 'between'"
                  v-model="filter.value"
                  size="block"
                  placeholder="Number"
                  can-edit
                  @blur="updateFilterValue(filter, 'value', $event)"
                  @keydown.enter="updateFilterValue(filter, 'value', filter.value)" />
                <NumberInput
                  v-if="filter.operator === 'between'"
                  :model-value="getFilterValueBetween(filter.value, 0)"
                  size="block"
                  placeholder="Number"
                  can-edit
                  @blur="setFilterValueBetween(filter, 0, $event)"
                  @keydown.enter="setFilterValueBetween()" />
                <NumberInput
                  v-if="filter.operator === 'between'"
                  :model-value="getFilterValueBetween(filter.value, 1)"
                  size="block"
                  placeholder="Number"
                  can-edit
                  @blur="setFilterValueBetween(filter, 1, $event)"
                  @keydown.enter="setFilterValueBetween(filter, 0, $event)" />
              </div>
            </div>
            <div v-else-if="getKey(filter, 'fieldType') === 'progress_item'">
              <SelectOneOfManyButtons
                v-model="filter.value"
                :values="[
                  { id: 'true', name: 'Yes' },
                  { id: 'false', name: 'No' },
                ]"
                @update:model-value="updateFilterValue(filter, 'value', $event)" />
            </div>
            <div v-else>
              <div class="italic text-soft">Not Implements</div>
            </div>
          </div>
        </div>
        <div>
          <VButton
            type="warning"
            size="xl"
            icon="fa-trash fa-regular"
            @click="removeFilter(filter)"></VButton>
        </div>
      </div>

      <div class="flex justify-start">
        <div class="mt-edge flex w-[120px] items-center gap-edge">
          <VDropdown
            :close-on-click="false"
            :set-focus="fieldFilters.length + preferenceFilters.length === 0 && quickPreferenceFilters.length === 0">
            <template #click-area>
              <VButton
                icon="fa-filter fa-regular"
                :emphasized="true"
                title="Add filters"
                size="sm" />
            </template>
            <template #dropdown="{ close }">
              <VList
                v-if="allItems.length"
                ref="target"
                :items="allItems(close)">
                <template #pre="{ item }">
                  <CheckBox
                    v-if="item.type !== 'header'"
                    :model-value="item.selected" />
                </template>
              </VList>
            </template>
          </VDropdown>
        </div>
      </div>
    </div>
  </FloatingWindowContainer>
</template>
