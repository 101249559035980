<script lang="ts" setup>
import { nextTick, onBeforeUnmount, onMounted, ref, useTemplateRef } from 'vue';
import { useEventListener } from '@vueuse/core';
import { ZIndexHoverBox } from '@/variables/z-indexes';

const {
  xPos,
  yPos,
  teleport = 'body',
  closeOnScroll = false,
  closeOnClick = false,
  listIsInverted = false,
  zIndex = ZIndexHoverBox,
  eventBounds = undefined,
  anchorToElement,
  anchorElementId,
  alwaysInViewPort = false,
  putOnTop = false,
} = defineProps<{
  xPos: number;
  yPos: number;
  teleport?: string;
  zIndex?: number;
  eventBounds?: DOMRect | null;
  closeOnScroll?: boolean;
  closeOnClick?: boolean;
  listIsInverted?: boolean | null;
  anchorToElement?: HTMLElement | null;
  anchorElementId?: string;
  alwaysInViewPort?: boolean;
  putOnTop?: boolean;
}>();

const emit = defineEmits<{
  (e: 'closed'): void;
  (event: 'update:listIsInverted', value: boolean): void;
}>();

const show = ref(false);
const wrapper = useTemplateRef<HTMLDivElement>('wrapper');

if (closeOnScroll) {
  useEventListener(
    'wheel',
    () => {
      emit('closed');
    },
    { passive: true }
  );
}

const supportsAnchor = CSS.supports('anchor-name', anchorElementId ?? 'not_valid');

const anchorPosY = ref(supportsAnchor ? 'top' : 'top');
const anchorPosX = ref(supportsAnchor ? 'right' : 'left');
const yValue = ref(supportsAnchor ? 'anchor(bottom)' : `${yPos}px`);
const xValue = ref(supportsAnchor ? 'anchor(right)' : `${xPos}px`);

const overflowVertically = (box: DOMRect) => {
  if (!box) throw new Error('overflowVertically');

  if (box.top < 0) return 'top';
  if (box.bottom > window.innerHeight) return 'bottom';
  return 'no';
};

const overflowHorizontal = (box: DOMRect) => {
  if (!box) throw new Error('overflowHorizontal');

  if (box.left < 0) return 'left';
  if (box.right > window.innerWidth) return 'right';
  return 'no';
};

const updatePositionOfBox = (elem: DOMRectReadOnly, show_it = true) => {
  if (!elem) return;

  const wrapperBox = elem;

  if ((anchorToElement || anchorElementId) && supportsAnchor) {
    switch (overflowVertically(wrapperBox)) {
      case 'bottom': {
        anchorPosY.value = 'bottom';
        yValue.value = 'anchor(top)';
        break;
      }
      case 'top':
      default: {
        anchorPosY.value = 'top';
        yValue.value = 'anchor(bottom)';
      }
    }
  } else if (eventBounds) {
    if (eventBounds.left - wrapperBox.width < 0) {
      xValue.value = `${eventBounds.right}px`;
      if (eventBounds.right + wrapperBox.width > window.innerWidth) {
        xValue.value = `${4}px`;
      }
    } else if (eventBounds.right + wrapperBox.width > window.innerWidth) {
      xValue.value = `${eventBounds.left - wrapperBox.width - 4}px`;
    }

    if (eventBounds.bottom + wrapperBox.height > window.innerHeight) {
      anchorPosY.value = 'bottom';
      let possibleValue = window.innerHeight - eventBounds.top + 1;
      if (possibleValue - wrapperBox.height < 0 && alwaysInViewPort) {
        anchorPosY.value = 'top';
        if (putOnTop) {
          possibleValue = eventBounds.top - wrapperBox.height - 2;
        } else {
          const overflowValue = eventBounds.bottom + wrapperBox.height - window.innerHeight;
          possibleValue = eventBounds.bottom - overflowValue - 2;
        }
      }
      yValue.value = `${possibleValue}px`;
    } else if (eventBounds.top - wrapperBox.height < 0) {
      anchorPosY.value = 'top';
      yValue.value = `${eventBounds.bottom}px`;
    }
  } else {
    if (wrapperBox.left < 0) {
      xValue.value = '0px';
    } else if (wrapperBox.right > window.innerWidth) {
      xValue.value = `${window.innerWidth - (wrapperBox.width + 4)}px`;
    }

    if (wrapperBox.top < 0) {
      yValue.value = '0px';
    } else if (wrapperBox.bottom > window.innerHeight) {
      yValue.value = `${window.innerHeight - (wrapperBox.height + 4)}px`;
    }
  }

  show.value = show_it;
};

const resize_ob = new ResizeObserver((e) => {
  if (wrapper.value && e[0]?.contentRect) {
    updatePositionOfBox(e[0].contentRect, true);
  }
});

onMounted(async () => {
  await nextTick();
  if (wrapper.value) {
    updatePositionOfBox(wrapper.value.getBoundingClientRect(), true);
    await nextTick();

    updatePositionOfBox(wrapper.value.getBoundingClientRect(), true);

    if ((supportsAnchor && anchorElementId) || eventBounds) {
      resize_ob.observe(wrapper.value);
    } else {
      await nextTick();

      updatePositionOfBox(wrapper.value.getBoundingClientRect(), true);
      setTimeout(() => {
        if (wrapper.value) {
          updatePositionOfBox(wrapper.value?.getBoundingClientRect(), true);
        }
      }, 250);
    }
  }
});

onBeforeUnmount(() => {
  if (supportsAnchor && anchorElementId) {
    resize_ob.disconnect();
  }
});

defineOptions({
  inheritAttrs: false,
});
</script>

<template>
  <teleport :to="teleport">
    <div
      v-if="closeOnClick"
      :style="`z-index: ${zIndex - 1}`"
      class="fixed left-0 top-0 h-dvh w-dvw bg-transparent"
      @click="$emit('closed')" />
    <div
      ref="wrapper"
      class="fixed overflow-hidden rounded border shadow-3xl"
      :class="[{ 'invisible': !show }, { 'bottom-0 [&>div]:bg-highlight': listIsInverted }, $attrs.class]"
      :style="`${anchorPosX}: ${xValue}; ${anchorPosY}: ${yValue}; z-index: ${zIndex}; ${$attrs.style}; position-anchor: ${anchorElementId}; `">
      <slot />
    </div>
  </teleport>
</template>
