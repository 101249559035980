<script setup lang="ts">
import ChevronToggle from '@/components/Icons/ChevronToggle.vue';
import ActionButtonGroup, { type ActionButtonProps } from '@/components/Inputs/Components/ActionButtonGroup.vue';
import { computed, onActivated, onDeactivated, onMounted, ref, watch } from 'vue';
import { getKey } from '@/util/globals';
import { twMerge } from 'tailwind-merge';

export type BoxContainerProps = {
  title?: string;
  openable?: boolean;
  contentPadding?: boolean;
  localStoreId?: string;
  actions?: ActionButtonProps[];
  overflowScroll?: boolean;
  headerSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  headerHeight?: string;
  paddingBottom?: boolean;
  noOverflowHidden?: boolean;
  toolTipText?: string;
  icon?: string;
  iconContainerBackground?: string;
  headerClass?: string;
  bodyClass?: string;
};

const {
  title = undefined,
  openable = false,
  contentPadding = true,
  localStoreId = undefined,
  actions = [],
  overflowScroll = false,
  noOverflowHidden = false,
  headerSize = 'h1',
  headerHeight = '68px',
  paddingBottom = true,
  toolTipText = '',
  iconContainerBackground = 'bg-[hsl(var(--color-event-type-blue))]',
  headerClass,
  bodyClass,
} = defineProps<BoxContainerProps>();

const emit = defineEmits<{
  (e: 'opened'): void;
  (e: 'closed'): void;
}>();

const isOpen = defineModel<boolean>({
  required: false,
  default: true,
});

watch(isOpen, (v) => {
  if (v) emit('opened');
  if (!v) emit('closed');
});

const shouldAnimate = ref(isOpen.value === false && openable);

if (localStoreId) {
  const value = localStorage.getItem(localStoreId);

  if (value?.length) {
    isOpen.value = value === 'true';
  }

  watch(isOpen, () => {
    localStorage.setItem(localStoreId, isOpen.value);
  });
}

const toggleOpen = () => {
  if (!openable) return;

  isOpen.value = !isOpen.value;
};

onActivated(() => {
  setTimeout(() => {
    if (!shouldAnimate.value) {
      shouldAnimate.value = true;
    }
  }, 500);
});

onDeactivated(() => {
  shouldAnimate.value = false;
});

onMounted(() => {
  setTimeout(() => {
    if (!shouldAnimate.value) {
      shouldAnimate.value = true;
    }
  }, 500);
});

const allActions = computed(() => {
  return actions
    .filter((i) => i !== null)
    .map((a) => ({
      ...a,
      emphasized: getKey(a, 'emphasized', true),
      size: 'sm',
    }));
});

defineOptions({
  inheritAttrs: false,
});
</script>
<template>
  <div
    :title="toolTipText"
    v-bind="$attrs"
    :class="
      twMerge(
        title || $slots.title ? 'overflow-hidden' : 'overflow-auto',
        $attrs.class,
        'group/box box-container-container flex flex-col rounded border bg shadow-md'
      )
    ">
    <div
      v-if="
        isOpen
          ? title || $slots.title || $slots['title-inner'] || actions?.length || $slots.actionButtons
          : title || $slots.title || $slots['title-inner']
      "
      aria-label="button"
      :class="
        twMerge(
          `box-container-title-container flex items-center justify-between gap-edge rounded-t bg px-edge ${{ 'cursor-pointer': openable }} ${{ 'rounded-b': !isOpen }} h-[${headerHeight}]`,
          headerClass
        )
      "
      @click="toggleOpen">
      <slot name="chevron">
        <ChevronToggle
          v-if="openable"
          classes="fa-sm fa-regular"
          :model-value="isOpen" />
      </slot>
      <div class="flex-1 py-edge">
        <slot
          name="title"
          :opened="isOpen">
          <component
            :is="headerSize"
            :class="{ 'flex items-center gap-edge': $slots.afterTitle }">
            <slot name="beforeTitle">
              <span
                v-if="icon"
                :class="iconContainerBackground"
                class="mr-edge inline-flex aspect-1 h-[28px] w-[28px] items-center justify-center rounded border">
                <i
                  v-if="icon"
                  class="fa fa-fw text-[hsl(var(--blue-1100))]"
                  :class="icon" />
              </span>
            </slot>
            <slot name="title-inner"> {{ title }}</slot>
            <slot name="afterTitle" />
          </component>
        </slot>
        <div v-if="$slots.underHeader">
          <slot name="underHeader"></slot>
        </div>
      </div>

      <div v-if="(actions.length > 0 || $slots.actionButtons) && isOpen">
        <ActionButtonGroup :actions="allActions" />
        <slot name="actionButtons" />
      </div>
    </div>

    <div
      :class="[
        { 'px-edge': contentPadding },
        { 'pt-edge': !title && !$slots.title && contentPadding && isOpen },
        { 'rounded-t': !title && !$slots.title },
        { 'pb-edge': (contentPadding || paddingBottom) && isOpen },
        { 'overflow-x-auto': !noOverflowHidden && overflowScroll },
        { 'overflow-x-hidden': !noOverflowHidden && !overflowScroll },
        { 'overflow-hidden': !noOverflowHidden },
        isOpen ? 'h-auto' : 'h-0',
        { 'box-content-animate transform transition-all duration-200': shouldAnimate },
        bodyClass,
      ]"
      :data-open="isOpen"
      class="box-container-content-container w-full rounded-b bg">
      <slot />
    </div>
  </div>
</template>

<style>
.box-content-animate {
  block-size: 0;
  display: none;
  transition-behavior: allow-discrete;
  box-sizing: border-box;
}

.box-content-animate[data-open='true'] {
  block-size: auto;
  block-size: calc-size(auto, size);
  display: block;

  @starting-style {
    block-size: 0;
    padding-block: 0;
  }
}
</style>
