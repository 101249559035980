<script setup lang="ts">
import CrudModal from '@/components/Modals/CrudModal.vue';
import { EventTypeResource } from '@/types/event-request';
import { ref } from 'vue';
import { useToast } from 'vue-toastification';
import { useMultipleButtonsModal } from '@/composables/modals/use-multiple-buttons-modal';
import VTimePicker from '@/components/Inputs/Date/VTimePicker.vue';
import SettingToggle from '@/components/Inputs/Components/SettingToggle.vue';
import type { CheckListResource } from '@/types/check-list';
import { requireInjection } from '@/util/require-inject';
import { eventTypesKey } from '@/provide/keys';

type Props = {
  eventType: EventTypeResource;
  checkList: CheckListResource;
  loading: boolean;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  'update:loading': boolean;
}>();

const toast = useToast();
const { threeButtonModal } = useMultipleButtonsModal();

const modalOpen = ref(true);
const checkListStart = ref<string | null>('12:00');
const { fetch: fetchEventTypes } = requireInjection(eventTypesKey);

const doTheAttachOfCheckList = async (addToAllFutureInvites = false) => {
  emit('update:loading', true);
  await axios.post(`/api/event-types/${props.eventType.id}/pivot/check-lists`, {
    check_list_id: props.checkList.id,
    attach: true,
    start: checkListStart.value,
    add_to_all_future_invites: addToAllFutureInvites,
  });

  await fetchEventTypes(true);

  emit('update:loading', false);

  if (addToAllFutureInvites) {
    toast.success(`Added ${props.checkList.title} to ${props.eventType.name}`);
  } else {
    toast.success(`${props.checkList.title} added`);
  }
};

const attachOfCheckList = async () => {
  if (!props.checkList || !props.checkList.id) return;
  if (checkListStart.value !== null && checkListStart.value?.length !== 5) {
    toast.warning('Start is not correctly formatted.');
    return;
  }

  const result = await threeButtonModal({
    title: `Add template to Events`,
    description: `Do you want to add ${props.checkList.title} to new events created only or previously created events?`,
    button: {
      type: 'primary',
      text: 'Add',
    },
    options: [
      {
        value: 'second',
        label: 'All Future Events',
      },
      {
        value: 'first',
        label: 'New Events Only',
      },
    ],
  });

  switch (result) {
    case 'first': {
      await doTheAttachOfCheckList(false);
      break;
    }
    case 'second': {
      await doTheAttachOfCheckList(true);
      break;
    }
    case 'cancel':
    default: {
      break;
    }
  }
  modalOpen.value = false;
};
</script>

<template>
  <CrudModal
    v-if="modalOpen"
    create-button-text="Attach"
    small
    :title="`Add Check List to ${eventType.name}`"
    @closed="modalOpen = false"
    @create="attachOfCheckList">
    <div class="space-y-edge">
      <SettingToggle
        :model-value="checkListStart !== null"
        label="Set Start"
        label-title="Would you like to set a start for this check list?"
        @update:model-value="checkListStart === null ? (checkListStart = '12:00') : (checkListStart = null)" />
      <VTimePicker
        v-if="checkListStart"
        v-model="checkListStart" />
      <VTimePicker
        v-else
        :model-value="null"
        :can-edit="false" />
    </div>
  </CrudModal>
</template>
