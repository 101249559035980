<script setup lang="ts">
import TemplateCrudModal from '@/components/Modals/TemplateCrudModal.vue';
import {
  destroyCheckLists,
  destroyCheckListsToGroup,
  patchCheckLists,
  postCheckLists,
  postCheckListsToGroup,
} from '@/services/api-check-lists';
import { CheckListResource, CheckListSmallResource } from '@/types/check-list';
import { GroupResource } from '@/types/group';
import { computed, nextTick, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import { useRecurringModal } from '@/composables/modals/use-recurring-modal';
import DateHourSelector from '@/components/Inputs/Date/DateHourSelector.vue';
import SettingToggle from '@/components/Inputs/Components/SettingToggle.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import TextareaInput from '@/components/Inputs/TextareaInput.vue';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import { useDeleteObjectModal } from '@/composables/modals/use-delete-object-modal';
import CheckBox from '@/components/Icons/CheckBox.vue';
import DisplayModal from '@/components/Modals/DisplayModal.vue';
import CheckList from '@/components/Models/CheckLists/CheckList.vue';
import RadioBox from '@/components/Icons/RadioBox.vue';
import { z } from 'zod';
import { formatQueryString, updateQueryString } from '@/util/query-helpers';
import { checkListStore } from '@/store/check-list-store';
import { useSmallScreen } from '@/composables/use-small-screen';
import { getRoute, openRoute } from '@/util/route';
import { getGroupById } from '@/util/group-helpers';

type Props = {
  model: 'Group' | 'Invite';
  modelId: number;
  templateGroupId?: number;
  isTemplate: boolean;
  isRecurring: boolean;
  start: string | null;
  initCheckList: CheckListSmallResource | null;
  group: GroupResource | null;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  created: [arg: CheckListResource];
  updated: [arg: CheckListResource];
  deleted: [arg: number];
  closed: [];
}>();

const toast = useToast();
const { recurringModal } = useRecurringModal();
const { assertReadyToDeleteModal } = useDeleteObjectModal();

const { fetchCheckLists } = checkListStore();

const loading = ref(false);

const checkListSchema = z.object({
  title: z.string().min(2).max(190),
  description: z.string().max(255),
  start: z.string().nullable(),
});

type CheckListType = z.infer<typeof checkListSchema>;

const checkList = ref<CheckListType>({
  title: props.initCheckList?.title || '',
  description: props.initCheckList?.description || '',
  start: props.initCheckList?.start || null,
});

const sharedGroupIds = ref<Set<number>>(new Set());
if (props.initCheckList?.groups?.length) {
  props.initCheckList.groups.forEach((groupId) => sharedGroupIds.value.add(groupId));
}

const canSave = computed(() => checkListSchema.safeParse(checkList.value).success);

const fromTemplate = ref(!props.isTemplate && !props.initCheckList?.id);
const selectedTemplateId = ref<number | null>(null);

watch(fromTemplate, () => {
  selectedTemplateId.value = null;
});

const createCheckList = async (close: () => void) => {
  let isGlobal = false;
  if (props.isRecurring) {
    const addToAll = await recurringModal('Check List', '', '');
    if (addToAll === 'cancel') return;
    if (addToAll === 'all') isGlobal = true;
  }

  const { data } = await postCheckLists(props.model, props.modelId, {
    title: checkList.value.title,
    description: checkList.value.description,
    is_global: isGlobal,
    template_id: selectedTemplateId.value,
    start: checkList.value.start,
  });

  await Promise.all(Array.from(sharedGroupIds.value).map((groupId) => postCheckListsToGroup(data.id, groupId)));

  updateQueryString(formatQueryString('CheckList', data.id), true);
  await nextTick();
  emit('created', { ...data, groups: Array.from(sharedGroupIds.value) });
  toast.success('Check List Created');
  close();
};

const updateCheckList = async (close: () => void) => {
  if (!props.initCheckList?.id) return;

  await patchCheckLists(props.initCheckList.id, {
    title: checkList.value.title,
    description: checkList.value.description,
    start: checkList.value.start,
  });

  // remove groups
  const removeGroups = props.initCheckList.groups.filter((groupId) => !sharedGroupIds.value.has(groupId));
  await Promise.all(removeGroups.map((groupId) => destroyCheckListsToGroup(props.initCheckList.id, groupId)));
  // add new groups
  const addGroups = Array.from(sharedGroupIds.value).filter((groupId) => !props.initCheckList.groups.includes(groupId));
  await Promise.all(addGroups.map((groupId) => postCheckListsToGroup(props.initCheckList.id, groupId)));

  emit('updated', {
    ...props.initCheckList,
    ...checkList.value,
    groups: Array.from(sharedGroupIds.value),
  });

  toast.success('Check List Updated');

  close();
};

const deleteCheckList = async (close: () => void) => {
  if (!props.initCheckList?.id) return;

  const deleteIt = await assertReadyToDeleteModal(
    'Delete Check List',
    `Are you sure that you want to delete ${props.initCheckList.title}?`
  );

  if (!deleteIt) return;

  await destroyCheckLists(props.initCheckList.id);

  toast.success('Check List Deleted');

  emit('deleted', props.initCheckList.id);

  close();
};

const checkListTemplates = ref<CheckListResource[]>([]);

const fetchAllTemplates = async () => {
  if (props.isTemplate || !props.templateGroupId) return;
  checkListTemplates.value = await fetchCheckLists('Group', props.templateGroupId);
};

if (!props.isTemplate && !props.initCheckList?.id) {
  fetchAllTemplates();
}

const toggleGroup = (id: number) => {
  if (sharedGroupIds.value.has(id)) {
    sharedGroupIds.value.delete(id);
  } else {
    sharedGroupIds.value.add(id);
  }
};

const selectTemplate = (template: CheckListSmallResource) => {
  selectedTemplateId.value = template.id;
  checkList.value.title = template.title ?? '';
  checkList.value.description = template.description ?? '';
};
const { isSmallScreen } = useSmallScreen();

const goToTemplate = async () => {
  const group = await getGroupById(props.templateGroupId);
  if (props.initCheckList && props.initCheckList.parent_id) {
    openRoute(
      getRoute('groups.administrator', group.slug) +
        '?open=' +
        formatQueryString('CheckList', props.initCheckList.parent_id) +
        '#check-lists'
    );
  } else {
    openRoute(getRoute('groups.administrator', group.slug) + '#check-lists');
  }
};
</script>

<template>
  <TemplateCrudModal
    v-model="fromTemplate"
    type="Check List"
    :loading="loading"
    :disabled="!canSave"
    :is-template="isTemplate"
    :update="initCheckList?.id > 0"
    :go-to-template="goToTemplate"
    @closed="$emit('closed')"
    @create="createCheckList"
    @update="updateCheckList"
    @delete="deleteCheckList">
    <div v-if="fromTemplate && checkListTemplates.length">
      <div class="-mx-edge max-h-[calc(30vh)] overflow-auto">
        <VTable
          row-size="small"
          edge-to-edge>
          <template #head>
            <VTableRow head>
              <VTableCell :colspan="2"> Templates</VTableCell>
            </VTableRow>
          </template>
          <VTableRow
            v-for="template in checkListTemplates"
            :key="template.id"
            main-row
            clickable
            @click="selectTemplate(template)">
            <VTableCell>
              <div class="flex gap-edge-1/4">
                <RadioBox :model-value="selectedTemplateId === template.id" />
                {{ template.title ?? 'Without Template' }}
              </div>
            </VTableCell>
            <VTableCell style="width: 35px">
              <DisplayModal
                v-if="template.id"
                :title="template.title"
                without-button-text
                @selected="selectTemplate(template)">
                <CheckList
                  :model-value="template"
                  :can-edit="false"
                  :group="group"
                  :is-template="false"
                  is-display />
              </DisplayModal>
            </VTableCell>
          </VTableRow>
        </VTable>
      </div>
    </div>
    <div class="form-layout mt-edge">
      <TextInput
        v-model="checkList.title"
        label="Title"
        required
        placeholder="Title of Check List" />

      <TextareaInput
        v-model="checkList.description"
        label="Description"
        placeholder="Description of Check List" />

      <SettingToggle
        v-if="!isTemplate"
        :model-value="checkList.start !== null"
        label="Set Start time"
        title="You can set the date and time on which the check list should be done."
        @update:model-value="checkList.start = checkList.start === null ? start : null" />

      <DateHourSelector
        v-if="!isTemplate"
        v-model:date-time="checkList.start"
        :vertical="isSmallScreen"
        :can-edit="checkList.start !== null"
        label="Start" />

      <div v-if="group?.childGroups.length">
        <h3
          class="pointer"
          title="These group will then see and submit this check list.">
          Share with your teams ({{ sharedGroupIds.size }}/{{ group.childGroups.length }})
        </h3>
        <VTable row-size="small">
          <VTableRow
            v-for="child in group.childGroups"
            :key="child.id"
            :main-row="sharedGroupIds.has(child.id)"
            clickable
            @click="toggleGroup(child.id)">
            <VTableCell>
              <div class="flex items-center gap-edge-1/4">
                <CheckBox :model-value="sharedGroupIds.has(child.id)" />
                <div class="mb-edge-1/4">
                  {{ child.name }}
                </div>
              </div>
            </VTableCell>
            <VTableCell />
          </VTableRow>
        </VTable>
      </div>
    </div>
  </TemplateCrudModal>
</template>
