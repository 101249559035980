type CountryCodeItem = {
  code: string;
  name: string;
  flag: string;
};

type CountryCodeSeparatorItem = {
  code: string;
  name: string;
  flag: string;
};
type CountryCodeOption = CountryCodeItem | CountryCodeSeparatorItem;

export const allCountryCodes = [
  {
    'code': '+47',
    'name': 'Norway',
    'flag': '🇳🇴',
  },
  {
    'code': '+46',
    'name': 'Sweden',
    'flag': '🇸🇪',
  },
  {
    'code': '+45',
    'name': 'Denmark',
    'flag': '🇩🇰',
  },
  {
    'code': '+44',
    'name': 'United Kingdom',
    'flag': '🇬🇧',
  },
  {
    'code': '+1',
    'name': 'United States',
    'flag': '🇺🇸',
  },
  {
    'separator': true,
  },
  {
    'code': '+93',
    'name': 'Afghanistan',
    'flag': '🇦🇫',
  },
  {
    'code': '+355',
    'name': 'Albania',
    'flag': '🇦🇱',
  },
  {
    'code': '+213',
    'name': 'Algeria',
    'flag': '🇩🇿',
  },
  {
    'code': '+376',
    'name': 'Andorra',
    'flag': '🇦🇩',
  },
  {
    'code': '+244',
    'name': 'Angola',
    'flag': '🇦🇴',
  },
  {
    'code': '+1-264',
    'name': 'Anguilla',
    'flag': '🇦🇮',
  },
  {
    'code': '+1-268',
    'name': 'Antigua and Barbuda',
    'flag': '🇦🇬',
  },
  {
    'code': '+54',
    'name': 'Argentina',
    'flag': '🇦🇷',
  },
  {
    'code': '+61',
    'name': 'Australia',
    'flag': '🇦🇺',
  },
  {
    'code': '+43',
    'name': 'Austria',
    'flag': '🇦🇹',
  },
  {
    'code': '+1-242',
    'name': 'Bahamas',
    'flag': '🇧🇸',
  },
  {
    'code': '+973',
    'name': 'Bahrain',
    'flag': '🇧🇭',
  },
  {
    'code': '+880',
    'name': 'Bangladesh',
    'flag': '🇧🇩',
  },
  {
    'code': '+1-246',
    'name': 'Barbados',
    'flag': '🇧🇧',
  },
  {
    'code': '+375',
    'name': 'Belarus',
    'flag': '🇧🇾',
  },
  {
    'code': '+32',
    'name': 'Belgium',
    'flag': '🇧🇪',
  },
  {
    'code': '+229',
    'name': 'Benin',
    'flag': '🇧🇯',
  },
  {
    'code': '+975',
    'name': 'Bhutan',
    'flag': '🇧🇹',
  },
  {
    'code': '+591',
    'name': 'Bolivia',
    'flag': '🇧🇴',
  },
  {
    'code': '+387',
    'name': 'Bosnia and Herzegovina',
    'flag': '🇧🇦',
  },
  {
    'code': '+267',
    'name': 'Botswana',
    'flag': '🇧🇼',
  },
  {
    'code': '+55',
    'name': 'Brazil',
    'flag': '🇧🇷',
  },
  {
    'code': '+1-284',
    'name': 'British Virgin Islands',
    'flag': '🇻🇬',
  },
  {
    'code': '+359',
    'name': 'Bulgaria',
    'flag': '🇧🇬',
  },
  {
    'code': '+226',
    'name': 'Burkina Faso',
    'flag': '🇧🇫',
  },
  {
    'code': '+257',
    'name': 'Burundi',
    'flag': '🇧🇮',
  },
  {
    'code': '+855',
    'name': 'Cambodia',
    'flag': '🇰🇭',
  },
  {
    'code': '+237',
    'name': 'Cameroon',
    'flag': '🇨🇲',
  },
  {
    'code': '+1',
    'name': 'Canada',
    'flag': '🇨🇦',
  },
  {
    'code': '+238',
    'name': 'Cape Verde',
    'flag': '🇨🇻',
  },
  {
    'code': '+1-345',
    'name': 'Cayman Islands',
    'flag': '🇰🇾',
  },
  {
    'code': '+236',
    'name': 'Central African Republic',
    'flag': '🇨🇫',
  },
  {
    'code': '+235',
    'name': 'Chad',
    'flag': '🇹🇩',
  },
  {
    'code': '+56',
    'name': 'Chile',
    'flag': '🇨🇱',
  },
  {
    'code': '+86',
    'name': 'China',
    'flag': '🇨🇳',
  },
  {
    'code': '+57',
    'name': 'Colombia',
    'flag': '🇨🇴',
  },
  {
    'code': '+269',
    'name': 'Comoros',
    'flag': '🇰🇲',
  },
  {
    'code': '+506',
    'name': 'Costa Rica',
    'flag': '🇨🇷',
  },
  {
    'code': '+385',
    'name': 'Croatia',
    'flag': '🇭🇷',
  },
  {
    'code': '+357',
    'name': 'Cyprus',
    'flag': '🇨🇾',
  },
  {
    'code': '+420',
    'name': 'Czech Republic',
    'flag': '🇨🇿',
  },
  {
    'code': '+243',
    'name': 'Democratic Republic of the Congo',
    'flag': '🇨🇩',
  },

  {
    'code': '+253',
    'name': 'Djibouti',
    'flag': '🇩🇯',
  },
  {
    'code': '+1-767',
    'name': 'Dominica',
    'flag': '🇩🇲',
  },
  {
    'code': '+1-809',
    'name': 'Dominican Republic',
    'flag': '🇩🇴',
  },
  {
    'code': '+593',
    'name': 'Ecuador',
    'flag': '🇪🇨',
  },
  {
    'code': '+20',
    'name': 'Egypt',
    'flag': '🇪🇬',
  },
  {
    'code': '+503',
    'name': 'El Salvador',
    'flag': '🇸🇻',
  },
  {
    'code': '+240',
    'name': 'Equatorial Guinea',
    'flag': '🇬🇶',
  },
  {
    'code': '+291',
    'name': 'Eritrea',
    'flag': '🇪🇷',
  },
  {
    'code': '+372',
    'name': 'Estonia',
    'flag': '🇪🇪',
  },
  {
    'code': '+268',
    'name': 'Eswatini',
    'flag': '🇸🇿',
  },
  {
    'code': '+251',
    'name': 'Ethiopia',
    'flag': '🇪🇹',
  },
  {
    'code': '+679',
    'name': 'Fiji',
    'flag': '🇫🇯',
  },
  {
    'code': '+358',
    'name': 'Finland',
    'flag': '🇫🇮',
  },
  {
    'code': '+33',
    'name': 'France',
    'flag': '🇫🇷',
  },
  {
    'code': '+594',
    'name': 'French Guiana',
    'flag': '🇬🇫',
  },
  {
    'code': '+689',
    'name': 'French Polynesia',
    'flag': '🇵🇫',
  },
  {
    'code': '+241',
    'name': 'Gabon',
    'flag': '🇬🇦',
  },
  {
    'code': '+220',
    'name': 'Gambia',
    'flag': '🇬🇲',
  },
  {
    'code': '+995',
    'name': 'Georgia',
    'flag': '🇬🇪',
  },
  {
    'code': '+49',
    'name': 'Germany',
    'flag': '🇩🇪',
  },
  {
    'code': '+233',
    'name': 'Ghana',
    'flag': '🇬🇭',
  },
  {
    'code': '+30',
    'name': 'Greece',
    'flag': '🇬🇷',
  },
  {
    'code': '+1-473',
    'name': 'Grenada',
    'flag': '🇬🇩',
  },
  {
    'code': '+590',
    'name': 'Guadeloupe',
    'flag': '🇬🇵',
  },
  {
    'code': '+1-671',
    'name': 'Guam',
    'flag': '🇬🇺',
  },
  {
    'code': '+502',
    'name': 'Guatemala',
    'flag': '🇬🇹',
  },
  {
    'code': '+224',
    'name': 'Guinea',
    'flag': '🇬🇳',
  },
  {
    'code': '+245',
    'name': 'Guinea-Bissau',
    'flag': '🇬🇼',
  },
  {
    'code': '+592',
    'name': 'Guyana',
    'flag': '🇬🇾',
  },
  {
    'code': '+509',
    'name': 'Haiti',
    'flag': '🇭🇹',
  },
  {
    'code': '+504',
    'name': 'Honduras',
    'flag': '🇭🇳',
  },
  {
    'code': '+36',
    'name': 'Hungary',
    'flag': '🇭🇺',
  },
  {
    'code': '+354',
    'name': 'Iceland',
    'flag': '🇮🇸',
  },
  {
    'code': '+91',
    'name': 'India',
    'flag': '🇮🇳',
  },
  {
    'code': '+62',
    'name': 'Indonesia',
    'flag': '🇮🇩',
  },
  {
    'code': '+98',
    'name': 'Iran',
    'flag': '🇮🇷',
  },
  {
    'code': '+964',
    'name': 'Iraq',
    'flag': '🇮🇶',
  },
  {
    'code': '+353',
    'name': 'Ireland',
    'flag': '🇮🇪',
  },
  {
    'code': '+972',
    'name': 'Israel',
    'flag': '🇮🇱',
  },
  {
    'code': '+39',
    'name': 'Italy',
    'flag': '🇮🇹',
  },
  {
    'code': '+225',
    'name': 'Ivory Coast',
    'flag': '🇨🇮',
  },
  {
    'code': '+1-876',
    'name': 'Jamaica',
    'flag': '🇯🇲',
  },
  {
    'code': '+81',
    'name': 'Japan',
    'flag': '🇯🇵',
  },
  {
    'code': '+962',
    'name': 'Jordan',
    'flag': '🇯🇴',
  },
  {
    'code': '+7',
    'name': 'Kazakhstan',
    'flag': '🇰🇿',
  },
  {
    'code': '+254',
    'name': 'Kenya',
    'flag': '🇰🇪',
  },
  {
    'code': '+383',
    'name': 'Kosovo',
    'flag': '🇽🇰',
  },
  {
    'code': '+965',
    'name': 'Kuwait',
    'flag': '🇰🇼',
  },
  {
    'code': '+996',
    'name': 'Kyrgyzstan',
    'flag': '🇰🇬',
  },
  {
    'code': '+856',
    'name': 'Laos',
    'flag': '🇱🇦',
  },
  {
    'code': '+371',
    'name': 'Latvia',
    'flag': '🇱🇻',
  },
  {
    'code': '+961',
    'name': 'Lebanon',
    'flag': '🇱🇧',
  },
  {
    'code': '+266',
    'name': 'Lesotho',
    'flag': '🇱🇸',
  },
  {
    'code': '+231',
    'name': 'Liberia',
    'flag': '🇱🇷',
  },
  {
    'code': '+218',
    'name': 'Libya',
    'flag': '🇱🇾',
  },
  {
    'code': '+423',
    'name': 'Liechtenstein',
    'flag': '🇱🇮',
  },
  {
    'code': '+370',
    'name': 'Lithuania',
    'flag': '🇱🇹',
  },
  {
    'code': '+352',
    'name': 'Luxembourg',
    'flag': '🇱🇺',
  },
  {
    'code': '+853',
    'name': 'Macau',
    'flag': '🇲🇴',
  },
  {
    'code': '+261',
    'name': 'Madagascar',
    'flag': '🇲🇬',
  },
  {
    'code': '+265',
    'name': 'Malawi',
    'flag': '🇲🇼',
  },
  {
    'code': '+60',
    'name': 'Malaysia',
    'flag': '🇲🇾',
  },
  {
    'code': '+960',
    'name': 'Maldives',
    'flag': '🇲🇻',
  },
  {
    'code': '+223',
    'name': 'Mali',
    'flag': '🇲🇱',
  },
  {
    'code': '+356',
    'name': 'Malta',
    'flag': '🇲🇹',
  },
  {
    'code': '+596',
    'name': 'Martinique',
    'flag': '🇲🇶',
  },
  {
    'code': '+222',
    'name': 'Mauritania',
    'flag': '🇲🇷',
  },
  {
    'code': '+230',
    'name': 'Mauritius',
    'flag': '🇲🇺',
  },
  {
    'code': '+52',
    'name': 'Mexico',
    'flag': '🇲🇽',
  },
  {
    'code': '+373',
    'name': 'Moldova',
    'flag': '🇲🇩',
  },
  {
    'code': '+377',
    'name': 'Monaco',
    'flag': '🇲🇨',
  },
  {
    'code': '+976',
    'name': 'Mongolia',
    'flag': '🇲🇳',
  },
  {
    'code': '+382',
    'name': 'Montenegro',
    'flag': '🇲🇪',
  },
  {
    'code': '+1-264',
    'name': 'Montserrat',
    'flag': '🇲🇸',
  },
  {
    'code': '+212',
    'name': 'Morocco',
    'flag': '🇲🇦',
  },
  {
    'code': '+258',
    'name': 'Mozambique',
    'flag': '🇲🇿',
  },
  {
    'code': '+264',
    'name': 'Namibia',
    'flag': '🇳🇦',
  },
  {
    'code': '+674',
    'name': 'Nauru',
    'flag': '🇳🇷',
  },
  {
    'code': '+977',
    'name': 'Nepal',
    'flag': '🇳🇵',
  },
  {
    'code': '+31',
    'name': 'Netherlands',
    'flag': '🇳🇱',
  },
  {
    'code': '+687',
    'name': 'New Caledonia',
    'flag': '🇳🇨',
  },
  {
    'code': '+64',
    'name': 'New Zealand',
    'flag': '🇳🇿',
  },
  {
    'code': '+505',
    'name': 'Nicaragua',
    'flag': '🇳🇮',
  },
  {
    'code': '+227',
    'name': 'Niger',
    'flag': '🇳🇪',
  },
  {
    'code': '+234',
    'name': 'Nigeria',
    'flag': '🇳🇬',
  },
  {
    'code': '+389',
    'name': 'North Macedonia',
    'flag': '🇲🇰',
  },

  {
    'code': '+968',
    'name': 'Oman',
    'flag': '🇴🇲',
  },
  {
    'code': '+92',
    'name': 'Pakistan',
    'flag': '🇵🇰',
  },
  {
    'code': '+970',
    'name': 'Palestine',
    'flag': '🇵🇸',
  },
  {
    'code': '+507',
    'name': 'Panama',
    'flag': '🇵🇦',
  },
  {
    'code': '+675',
    'name': 'Papua New Guinea',
    'flag': '🇵🇬',
  },
  {
    'code': '+595',
    'name': 'Paraguay',
    'flag': '🇵🇾',
  },
  {
    'code': '+51',
    'name': 'Peru',
    'flag': '🇵🇪',
  },
  {
    'code': '+63',
    'name': 'Philippines',
    'flag': '🇵🇭',
  },
  {
    'code': '+48',
    'name': 'Poland',
    'flag': '🇵🇱',
  },
  {
    'code': '+351',
    'name': 'Portugal',
    'flag': '🇵🇹',
  },
  {
    'code': '+1-787',
    'name': 'Puerto Rico',
    'flag': '🇵🇷',
  },
  {
    'code': '+974',
    'name': 'Qatar',
    'flag': '🇶🇦',
  },
  {
    'code': '+40',
    'name': 'Romania',
    'flag': '🇷🇴',
  },
  {
    'code': '+7',
    'name': 'Russia',
    'flag': '🇷🇺',
  },
  {
    'code': '+250',
    'name': 'Rwanda',
    'flag': '🇷🇼',
  },
  {
    'code': '+685',
    'name': 'Samoa',
    'flag': '🇼🇸',
  },
  {
    'code': '+378',
    'name': 'San Marino',
    'flag': '🇸🇲',
  },
  {
    'code': '+239',
    'name': 'São Tomé and Príncipe',
    'flag': '🇸🇹',
  },
  {
    'code': '+966',
    'name': 'Saudi Arabia',
    'flag': '🇸🇦',
  },
  {
    'code': '+221',
    'name': 'Senegal',
    'flag': '🇸🇳',
  },
  {
    'code': '+381',
    'name': 'Serbia',
    'flag': '🇷🇸',
  },
  {
    'code': '+248',
    'name': 'Seychelles',
    'flag': '🇸🇨',
  },
  {
    'code': '+232',
    'name': 'Sierra Leone',
    'flag': '🇸🇱',
  },
  {
    'code': '+65',
    'name': 'Singapore',
    'flag': '🇸🇬',
  },
  {
    'code': '+421',
    'name': 'Slovakia',
    'flag': '🇸🇰',
  },
  {
    'code': '+386',
    'name': 'Slovenia',
    'flag': '🇸🇮',
  },
  {
    'code': '+677',
    'name': 'Solomon Islands',
    'flag': '🇸🇧',
  },
  {
    'code': '+252',
    'name': 'Somalia',
    'flag': '🇸🇴',
  },
  {
    'code': '+27',
    'name': 'South Africa',
    'flag': '🇿🇦',
  },
  {
    'code': '+82',
    'name': 'South Korea',
    'flag': '🇰🇷',
  },
  {
    'code': '+211',
    'name': 'South Sudan',
    'flag': '🇸🇸',
  },
  {
    'code': '+34',
    'name': 'Spain',
    'flag': '🇪🇸',
  },
  {
    'code': '+94',
    'name': 'Sri Lanka',
    'flag': '🇱🇰',
  },
  {
    'code': '+249',
    'name': 'Sudan',
    'flag': '🇸🇩',
  },
  {
    'code': '+597',
    'name': 'Suriname',
    'flag': '🇸🇷',
  },

  {
    'code': '+41',
    'name': 'Switzerland',
    'flag': '🇨🇭',
  },
  {
    'code': '+886',
    'name': 'Taiwan',
    'flag': '🇹🇼',
  },
  {
    'code': '+992',
    'name': 'Tajikistan',
    'flag': '🇹🇯',
  },
  {
    'code': '+255',
    'name': 'Tanzania',
    'flag': '🇹🇿',
  },
  {
    'code': '+66',
    'name': 'Thailand',
    'flag': '🇹🇭',
  },
  {
    'code': '+228',
    'name': 'Togo',
    'flag': '🇹🇬',
  },
  {
    'code': '+676',
    'name': 'Tonga',
    'flag': '🇹🇴',
  },
  {
    'code': '+1-868',
    'name': 'Trinidad and Tobago',
    'flag': '🇹🇹',
  },
  {
    'code': '+216',
    'name': 'Tunisia',
    'flag': '🇹🇳',
  },
  {
    'code': '+90',
    'name': 'Turkey',
    'flag': '🇹🇷',
  },
  {
    'code': '+993',
    'name': 'Turkmenistan',
    'flag': '🇹🇲',
  },
  {
    'code': '+688',
    'name': 'Tuvalu',
    'flag': '🇹🇻',
  },
  {
    'code': '+1-340',
    'name': 'U.S. Virgin Islands',
    'flag': '🇻🇮',
  },
  {
    'code': '+256',
    'name': 'Uganda',
    'flag': '🇺🇬',
  },
  {
    'code': '+380',
    'name': 'Ukraine',
    'flag': '🇺🇦',
  },
  {
    'code': '+971',
    'name': 'United Arab Emirates',
    'flag': '🇦🇪',
  },

  {
    'code': '+598',
    'name': 'Uruguay',
    'flag': '🇺🇾',
  },
  {
    'code': '+998',
    'name': 'Uzbekistan',
    'flag': '🇺🇿',
  },
  {
    'code': '+678',
    'name': 'Vanuatu',
    'flag': '🇻🇺',
  },
  {
    'code': '+58',
    'name': 'Venezuela',
    'flag': '🇻🇪',
  },
  {
    'code': '+84',
    'name': 'Vietnam',
    'flag': '🇻🇳',
  },
  {
    'code': '+967',
    'name': 'Yemen',
    'flag': '🇾🇪',
  },
  {
    'code': '+260',
    'name': 'Zambia',
    'flag': '🇿🇲',
  },
  {
    'code': '+263',
    'name': 'Zimbabwe',
    'flag': '🇿🇼',
  },
] as ReadonlyArray<CountryCodeOption>;

export const countryToPhoneCode = {
  'AF': '+93', // Afghanistan
  'AL': '+355', // Albania
  'DZ': '+213', // Algeria
  'AS': '+1-684', // American Samoa
  'AD': '+376', // Andorra
  'AO': '+244', // Angola
  'AI': '+1-264', // Anguilla
  'AQ': '+672', // Antarctica
  'AG': '+1-268', // Antigua and Barbuda
  'AR': '+54', // Argentina
  'AM': '+374', // Armenia
  'AW': '+297', // Aruba
  'AU': '+61', // Australia
  'AT': '+43', // Austria
  'AZ': '+994', // Azerbaijan
  'BS': '+1-242', // Bahamas
  'BH': '+973', // Bahrain
  'BD': '+880', // Bangladesh
  'BB': '+1-246', // Barbados
  'BY': '+375', // Belarus
  'BE': '+32', // Belgium
  'BZ': '+501', // Belize
  'BJ': '+229', // Benin
  'BM': '+1-441', // Bermuda
  'BT': '+975', // Bhutan
  'BO': '+591', // Bolivia
  'BA': '+387', // Bosnia and Herzegovina
  'BW': '+267', // Botswana
  'BR': '+55', // Brazil
  'IO': '+246', // British Indian Ocean Territory
  'VG': '+1-284', // British Virgin Islands
  'BN': '+673', // Brunei
  'BG': '+359', // Bulgaria
  'BF': '+226', // Burkina Faso
  'BI': '+257', // Burundi
  'KH': '+855', // Cambodia
  'CM': '+237', // Cameroon
  'CA': '+1', // Canada
  'CV': '+238', // Cape Verde
  'KY': '+1-345', // Cayman Islands
  'CF': '+236', // Central African Republic
  'TD': '+235', // Chad
  'CL': '+56', // Chile
  'CN': '+86', // China
  'CX': '+61', // Christmas Island
  'CC': '+61', // Cocos Islands
  'CO': '+57', // Colombia
  'KM': '+269', // Comoros
  'CK': '+682', // Cook Islands
  'CR': '+506', // Costa Rica
  'HR': '+385', // Croatia
  'CU': '+53', // Cuba
  'CW': '+599', // Curaçao
  'CY': '+357', // Cyprus
  'CZ': '+420', // Czech Republic
  'CD': '+243', // Democratic Republic of the Congo
  'DK': '+45', // Denmark
  'DJ': '+253', // Djibouti
  'DM': '+1-767', // Dominica
  'DO': '+1-809', // Dominican Republic
  'TL': '+670', // East Timor
  'EC': '+593', // Ecuador
  'EG': '+20', // Egypt
  'SV': '+503', // El Salvador
  'GQ': '+240', // Equatorial Guinea
  'ER': '+291', // Eritrea
  'EE': '+372', // Estonia
  'ET': '+251', // Ethiopia
  'FK': '+500', // Falkland Islands
  'FO': '+298', // Faroe Islands
  'FJ': '+679', // Fiji
  'FI': '+358', // Finland
  'FR': '+33', // France
  'PF': '+689', // French Polynesia
  'GA': '+241', // Gabon
  'GM': '+220', // Gambia
  'GE': '+995', // Georgia
  'DE': '+49', // Germany
  'GH': '+233', // Ghana
  'GI': '+350', // Gibraltar
  'GR': '+30', // Greece
  'GL': '+299', // Greenland
  'GD': '+1-473', // Grenada
  'GU': '+1-671', // Guam
  'GT': '+502', // Guatemala
  'GG': '+44-1481', // Guernsey
  'GN': '+224', // Guinea
  'GW': '+245', // Guinea-Bissau
  'GY': '+592', // Guyana
  'HT': '+509', // Haiti
  'HN': '+504', // Honduras
  'HK': '+852', // Hong Kong
  'HU': '+36', // Hungary
  'IS': '+354', // Iceland
  'IN': '+91', // India
  'ID': '+62', // Indonesia
  'IR': '+98', // Iran
  'IQ': '+964', // Iraq
  'IE': '+353', // Ireland
  'IM': '+44-1624', // Isle of Man
  'IL': '+972', // Israel
  'IT': '+39', // Italy
  'CI': '+225', // Ivory Coast
  'JM': '+1-876', // Jamaica
  'JP': '+81', // Japan
  'JE': '+44-1534', // Jersey
  'JO': '+962', // Jordan
  'KZ': '+7', // Kazakhstan
  'KE': '+254', // Kenya
  'KI': '+686', // Kiribati
  'XK': '+383', // Kosovo
  'KW': '+965', // Kuwait
  'KG': '+996', // Kyrgyzstan
  'LA': '+856', // Laos
  'LV': '+371', // Latvia
  'LB': '+961', // Lebanon
  'LS': '+266', // Lesotho
  'LR': '+231', // Liberia
  'LY': '+218', // Libya
  'LI': '+423', // Liechtenstein
  'LT': '+370', // Lithuania
  'LU': '+352', // Luxembourg
  'MO': '+853', // Macau
  'MK': '+389', // Macedonia
  'MG': '+261', // Madagascar
  'MW': '+265', // Malawi
  'MY': '+60', // Malaysia
  'MV': '+960', // Maldives
  'ML': '+223', // Mali
  'MT': '+356', // Malta
  'MH': '+692', // Marshall Islands
  'MR': '+222', // Mauritania
  'MU': '+230', // Mauritius
  'YT': '+262', // Mayotte
  'MX': '+52', // Mexico
  'FM': '+691', // Micronesia
  'MD': '+373', // Moldova
  'MC': '+377', // Monaco
  'MN': '+976', // Mongolia
  'ME': '+382', // Montenegro
  'MS': '+1-664', // Montserrat
  'MA': '+212', // Morocco
  'MZ': '+258', // Mozambique
  'MM': '+95', // Myanmar
  'NA': '+264', // Namibia
  'NR': '+674', // Nauru
  'NP': '+977', // Nepal
  'NL': '+31', // Netherlands
  'AN': '+599', // Netherlands Antilles
  'NC': '+687', // New Caledonia
  'NZ': '+64', // New Zealand
  'NI': '+505', // Nicaragua
  'NE': '+227', // Niger
  'NG': '+234', // Nigeria
  'NU': '+683', // Niue
  'KP': '+850', // North Korea
  'MP': '+1-670', // Northern Mariana Islands
  'NO': '+47', // Norway
  'OM': '+968', // Oman
  'PK': '+92', // Pakistan
  'PW': '+680', // Palau
  'PS': '+970', // Palestine
  'PA': '+507', // Panama
  'PG': '+675', // Papua New Guinea
  'PY': '+595', // Paraguay
  'PE': '+51', // Peru
  'PH': '+63', // Philippines
  'PN': '+64', // Pitcairn
  'PL': '+48', // Poland
  'PT': '+351', // Portugal
  'PR': '+1-787', // Puerto Rico
  'QA': '+974', // Qatar
  'CG': '+242', // Republic of the Congo
  'RE': '+262', // Reunion
  'RO': '+40', // Romania
  'RU': '+7', // Russia
  'RW': '+250', // Rwanda
  'BL': '+590', // Saint Barthelemy
  'SH': '+290', // Saint Helena
  'KN': '+1-869', // Saint Kitts and Nevis
  'LC': '+1-758', // Saint Lucia
  'MF': '+590', // Saint Martin
  'PM': '+508', // Saint Pierre and Miquelon
  'VC': '+1-784', // Saint Vincent and the Grenadines
  'WS': '+685', // Samoa
  'SM': '+378', // San Marino
  'ST': '+239', // Sao Tome and Principe
  'SA': '+966', // Saudi Arabia
  'SN': '+221', // Senegal
  'RS': '+381', // Serbia
  'SC': '+248', // Seychelles
  'SL': '+232', // Sierra Leone
  'SG': '+65', // Singapore
  'SX': '+1-721', // Sint Maarten
  'SK': '+421', // Slovakia
  'SI': '+386', // Slovenia
  'SB': '+677', // Solomon Islands
  'SO': '+252', // Somalia
  'ZA': '+27', // South Africa
  'KR': '+82', // South Korea
  'SS': '+211', // South Sudan
  'ES': '+34', // Spain
  'LK': '+94', // Sri Lanka
  'SD': '+249', // Sudan
  'SR': '+597', // Suriname
  'SJ': '+47', // Svalbard and Jan Mayen
  'SZ': '+268', // Swaziland
  'SE': '+46', // Sweden
  'CH': '+41', // Switzerland
  'SY': '+963', // Syria
  'TW': '+886', // Taiwan
  'TJ': '+992', // Tajikistan
  'TZ': '+255', // Tanzania
  'TH': '+66', // Thailand
  'TG': '+228', // Togo
  'TK': '+690', // Tokelau
  'TO': '+676', // Tonga
  'TT': '+1-868', // Trinidad and Tobago
  'TN': '+216', // Tunisia
  'TR': '+90', // Turkey
  'TM': '+993', // Turkmenistan
  'TC': '+1-649', // Turks and Caicos Islands
  'TV': '+688', // Tuvalu
  'VI': '+1-340', // U.S. Virgin Islands
  'UG': '+256', // Uganda
  'UA': '+380', // Ukraine
  'AE': '+971', // United Arab Emirates
  'GB': '+44', // United Kingdom
  'US': '+1', // United States
  'UY': '+598', // Uruguay
  'UZ': '+998', // Uzbekistan
  'VU': '+678', // Vanuatu
  'VA': '+379', // Vatican
  'VE': '+58', // Venezuela
  'VN': '+84', // Vietnam
  'WF': '+681', // Wallis and Futuna
  'EH': '+212', // Western Sahara
  'YE': '+967', // Yemen
  'ZM': '+260', // Zambia
  'ZW': '+263', // Zimbabwe
} as const;
