import type { ContextSidebarTab } from '@/types/global';
import type { GroupContextSidebarResource } from '@/types/group';
import { usePage } from '@inertiajs/vue3';
import { getRoute } from '@/util/route';
// import { groupRequestTabs } from '@/util/tabs/views/group-show/group-request-tabs';
import { groupContactListTabs } from '@/util/tabs/views/group-show/group-contact-list-tabs';
import { groupRoomTabs } from '@/util/tabs/views/group-show/group-room-tabs';
import { groupMemberTabs } from '@/util/tabs/views/group-show/group-member-tabs';

export const isAdminOfGroup = (isAdmin: boolean, group: GroupContextSidebarResource, parentId: number | null) => {
  if (isAdmin) {
    return true;
  }
  const { groups } = usePage().props.auth.user;
  const maybeGroup = groups.find((g) => g.id === group.id);
  if (maybeGroup?.admin) {
    return true;
  }
  const parentG = groups.find((g) => g.id === group.parent_id);
  return !!parentG?.admin;
};

const groupTabsForSmallScreen = (group: GroupContextSidebarResource, admin: boolean): ContextSidebarTab[] => {
  const array = [
    {
      hash: '',
      parentKey: `group_${group.id}`,
      title: 'Group Calendar ',
      icon: 'fa-home',
      route: getRoute('groups.show', group.slug),
    },
  ];
  if (group.read || admin) {
    groupMemberTabs(group.has_shifts, admin).forEach((t) => {
      array.push({
        hash: t.key,
        key: t.key,
        parentKey: `group_${group.id}_members`,
        title: ` ${t.key === 'members' ? 'Members' : ''} ${t.title}`,
        route: getRoute('groups.show', group.slug),
      });
    });

    if (group.venue_id) {
      groupRoomTabs().forEach((t) => {
        array.push({
          hash: t.key,
          key: t.key,
          parentKey: `group_${group.id}_rooms`,
          title: `${t.key === 'rooms' ? 'Rooms' : ''} ${t.title} `,
          route: getRoute('groups.show', group.slug),
        });
      });
    }
    if (group.share_venue_calendar) {
      array.push({
        hash: '',
        key: '',
        parentKey: `group_${group.id}_rooms`,
        title: 'Rooms',
        route: getRoute('groups.show', group.slug),
      });
    }
    if (group.has_partners) {
      groupContactListTabs().forEach((t) => {
        array.push({
          hash: t.key,
          key: t.key,
          parentKey: `group_${group.id}_contact_lists`,
          title: t.title,
          route: getRoute('groups.show', group.slug),
        });
      });
    }

    array.push({
      hash: 'documents',
      parentKey: `group_${group.id}_documents`,
      title: 'Documents',
      icon: 'fa-file',
      route: getRoute('groups.show', group.slug),
    });
  }
  return array;
};

export const groupTabs = (
  group: GroupContextSidebarResource,
  isAdmin: boolean,
  isSmallScreen = false,
  withAdminPage = true
): ContextSidebarTab[] => {
  const admin = isAdminOfGroup(isAdmin, group, group.parent_id);

  if (isSmallScreen) {
    return groupTabsForSmallScreen(group, admin);
  }
  const array = [
    {
      hash: '',
      key: `group_${group.id}`,
      title: 'Calendar',
      icon: 'fa-home fa-regular',
      route: getRoute('groups.show', group.slug),
    },
  ];
  if (group.read || admin) {
    array.push({
      hash: 'members',
      key: `group_${group.id}_members`,
      title: 'Members',
      icon: 'fa-user-group fa-regular',
      route: getRoute('groups.show', group.slug),
      completionTrackerName: 'has_invited_to_group',
    });
    if (group.venue_id) {
      array.push({
        hash: 'rooms',
        key: `group_${group.id}_rooms`,
        title: 'Rooms',
        icon: 'fa-cube fa-regular',
        route: getRoute('groups.show', group.slug),
        completionTrackerName: 'has_created_rooms',
      });
    } else if (!group.venue_id && group.share_venue_calendar) {
      array.push({
        hash: 'rooms',
        key: `group_${group.id}_rooms`,
        title: 'Rooms',
        icon: 'fa-cube fa-regular',
        route: getRoute('groups.show', group.slug),
      });
    } else if (group.share_venue_calendar) {
      array.push({
        hash: 'rooms',
        key: `group_${group.id}_rooms`,
        title: 'Rooms',
        icon: 'fa-cube',
        route: getRoute('groups.show', group.slug),
      });
    }
    if (group.has_performances && admin) {
      array.push({
        hash: 'performances',
        key: `group_${group.id}_performances`,
        title: 'Performances',
        icon: 'fa-music fa-regular',
        route: getRoute('groups.show', group.slug),
      });
    }
    if (group.has_partners && group.parent_id === null && admin) {
      array.push({
        hash: 'contact_lists',
        key: `group_${group.id}_contact_lists`,
        title: 'Contact Lists',
        icon: 'fa-address-book fa-regular',
        route: getRoute('groups.show', group.slug),
      });
    }
    if (group.has_tasks) {
      array.push({
        hash: 'tasks',
        key: `group_${group.id}_tasks`,
        title: 'Tasks',
        icon: 'fa-tasks fa-regular',
        route: getRoute('groups.show', group.slug),
      });
    }
    if (group.parent_id === null && admin) {
      array.push({
        hash: 'requests',
        key: `group_${group.id}_requests`,
        title: 'Requests',
        icon: 'fa-inbox',
        route: getRoute('groups.show', group.slug),
      });
    }

    array.push({
      hash: 'documents',
      key: `group_${group.id}_documents`,
      title: 'Documents',
      icon: 'fa-folder-o',
      route: getRoute('groups.show', group.slug),
    });

    // if (admin) {
    //   array.push({
    //     hash: 'reports',
    //     key: `group_${group.id}_reports`,
    //     title: 'Reports',
    //     icon: 'fa-file-chart-column',
    //     route: getRoute('groups.show', group.slug),
    //   });
    // }

    if (group.parent_id === null && withAdminPage && admin) {
      array.push({
        hash: '',
        key: `group_${group.id}_settings`,
        title: 'Settings',
        icon: 'fa-cog fa-regular',
        route: getRoute('groups.administrator', group.slug),
      });
    }
  }

  return array;
};
