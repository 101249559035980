import { usePage } from '@inertiajs/vue3';
import { getKey } from '@/util/globals';
import { useUserStore } from '@/store/UserStore';

export const orderReportSetupKey = 'orderReportSetup';
export const canSeePerformanceInfoAccesses = 'performanceInfoAccess';
export const canSeePerformanceConnectedUsers = 'performanceConnectedUsers';

export const hasAccessToFeatureInTesting = (featureKey?: null | string) => {
  const userStore = useUserStore();
  if (userStore && userStore.hideExperimental) {
    return false;
  }
  if (!usePage().props?.authenticated) return false;
  if (!usePage().props?.auth.user) return false;
  if (usePage().props?.auth.user.is_admin) return true;
  if (!featureKey) return false;
  return getKey(usePage().props?.auth?.user, 'test_features', [])?.includes(featureKey);
};
