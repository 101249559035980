export const fullScreenCalendarIndex = 400;
export const fullScreenCalendarModal = 401;
export const ZIndexEventMenu = 2000;
export const ZIndexCrudModal = 3650;
export const ZIndexPopUpModal = 3700;
export const ZIndexHoverBox = 4500;
export const ZIndexDropDown = 3550;
export const z_index_link_box = 99999;
export const z_index_completion_tracker = 100;
export const z_index_no_read_view = 10;
export const z_index_base_slideout = 1;
export const z_index_split_view = 22;
export const z_index_event_request_slideout = 105;
export const z_index_room_booking_slideout = 106;
