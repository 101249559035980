<script lang="ts" setup>
import { nextTick, ref } from 'vue';
import CrudModal from '@/components/Modals/CrudModal.vue';
import TextareaInput from '@/components/Inputs/TextareaInput.vue';
import VSelect from '@/components/Inputs/VSelect.vue';
import { getItemFromArrayBasedOnId, getKey } from '@/util/globals';
import NumberInput from '@/components/Inputs/NumberInput.vue';
import { useToast } from 'vue-toastification';
import SettingToggle from '@/components/Inputs/Components/SettingToggle.vue';
import VRichText from '@/components/Inputs/VRichText.vue';
import { AccreditationLevelResource } from '@/types/accreditation';
import TextInput from '@/components/Inputs/TextInput.vue';
import VButton from '@/components/Inputs/VButton.vue';
import DateHourSelector from '@/components/Inputs/Date/DateHourSelector.vue';
import ToggleWithInput from '@/components/Inputs/Components/ToggleWithInput.vue';
import { changeAndFormatStamp, getNow } from '@/util/timeFunctions';
import ActionButtonGroup from '@/components/Inputs/Components/ActionButtonGroup.vue';
import { getGroup } from '@/services/api-group';
import { getRoute, openRoute } from '@/util/route';

type Props = {
  initialGuestList: any;
  guestListSettings: any;
  modelType: string;
  defaultStart?: string | null;
  defaultEnd?: string | null;
  modelId: number;
  accreditationLevels?: AccreditationLevelResource[] | null;
  parentModel?: string | null;
  parentModelId?: number | null;
};

const props = withDefaults(defineProps<Props>(), {
  accreditationLevels: () => [],
  parentModel: null,
  parentModelId: null,
  defaultStart: () => changeAndFormatStamp({ stamp: getNow(), startOf: 'day' }),
  defaultEnd: () => changeAndFormatStamp({ stamp: getNow(), endOf: 'day' }),
});

const emit = defineEmits<{
  (e: 'closed'): void;
  (e: 'fetch'): void;
  (e: 'created', value: any): void;
  (e: 'updated', value: any): void;
  (e: 'deleted', value: any): void;
}>();

const modalOpen = ref(false);
const generateUuid = ref(false);
const guestList = ref(null);
const openModal = async () => {
  modalOpen.value = false;
  guestList.value = {
    id: getKey(props.initialGuestList, 'id'),
    name: getKey(props.initialGuestList, 'name'),
    notes: getKey(props.initialGuestList, 'notes'),
    allowed_guests: getKey(props.initialGuestList, 'allowed_guests', null),
    accreditation_level_id: getKey(props.initialGuestList, 'accreditation_level_id', null),
    link_description: getKey(props.initialGuestList, 'link_description', null),
    uuid_can_delete: getKey(props.initialGuestList, 'uuid_can_delete', true),
    uuid: getKey(props.initialGuestList, 'uuid', null),
    title: getKey(props.initialGuestList, 'title', null),
    open_from: getKey(props.initialGuestList, 'open_from', null),
    open_to: getKey(props.initialGuestList, 'open_to', null),
  };
  await nextTick();
  modalOpen.value = true;
};
openModal();

const createGuestList = async () => {
  await axios.post('/api/guest-lists', {
    guest_list_setting_id: props.guestListSettings.id,
    model_type: props.modelType,
    model_id: props.modelId,
    notes: guestList.value.notes,
    accreditation_level_id: guestList.value.accreditation_level_id,
    allowed_guests: guestList.value.allowed_guests,
    link_description: guestList.value.link_description,
    uuid_can_delete: guestList.value.uuid_can_delete,
    title: guestList.value.title,
    generate_uuid: generateUuid.value,
    open_from: guestList.value.open_from,
    open_to: guestList.value.open_to,
  });
  useToast().success('Guest list created');
  modalOpen.value = false;
  emit('fetch');
};

const updateGuestList = async () => {
  await axios.patch(`/api/guest-lists/${props.initialGuestList.id}`, {
    notes: guestList.value.notes,
    accreditation_level_id: guestList.value.accreditation_level_id,
    allowed_guests: guestList.value.allowed_guests,
    link_description: guestList.value.link_description,
    uuid_can_delete: guestList.value.uuid_can_delete,
    title: guestList.value.title,
    open_from: guestList.value.open_from,
    open_to: guestList.value.open_to,
  });
  modalOpen.value = false;
  emit('fetch');
};

const addUuidToGuestList = async () => {
  if (!getKey(props.initialGuestList, 'id')) return;
  const { data } = await axios.post('/api/guest-lists/' + props.initialGuestList.id + '/uuid');
  guestList.value.uuid = data.uuid;
  emit('updated', { id: props.initialGuestList.id, uuid: data.uuid });
};
const removeUuidFromGuestList = async () => {
  if (!getKey(props.initialGuestList, 'id')) return;
  await axios.delete('/api/guest-lists/' + props.initialGuestList.id + '/uuid');
  guestList.value.uuid = null;
  emit('updated', { id: props.initialGuestList.id, uuid: null });
};
const deleteGuestList = async () => {
  if (
    !props.initialGuestList ||
    !getKey(props.initialGuestList, 'id') ||
    getKey(props.initialGuestList, 'listable_type') !== 'App\\Invite'
  )
    return;
  await axios.delete(`/api/guest-lists/${props.initialGuestList.id}`);
  modalOpen.value = false;
  emit('fetch');
  useToast().info('Deleted');
};

const goToTemplate = async () => {
  if (props.parentModel !== 'Group' || !props.parentModelId) return;

  const { data: group } = await getGroup(props.parentModelId);

  openRoute(getRoute('groups.administrator', group.slug) + '#meta-data');
};
</script>

<template>
  <CrudModal
    v-if="modalOpen"
    :title="(guestList.id !== null ? 'Update' : 'Create') + ' Guest List'"
    :update="guestList.id !== null"
    :delete-button="initialGuestList && initialGuestList.listable_type === 'App\\Invite'"
    medium
    @delete="deleteGuestList"
    @update="updateGuestList"
    @create="createGuestList"
    @closed="$emit('closed')">
    <div class="form-layout">
      <VSelect
        v-if="accreditationLevels.length > 0"
        v-model="guestList.accreditation_level_id"
        label="Accreditation Level"
        nullable
        :nullable-display-text="
          'Same as Default Settings (' +
          getItemFromArrayBasedOnId(guestListSettings.accreditation_level_id, accreditationLevels, { title: 'N/A' })
            .title +
          ')'
        "
        option-value="title"
        :options="accreditationLevels" />

      <SettingToggle
        :model-value="guestList.allowed_guests !== null"
        label="Override Default Settings"
        :title="'Use default number of guests of ' + guestListSettings.allowed_guests + ' from settings'"
        @update:model-value="
          guestList.allowed_guests = guestList.allowed_guests === null ? guestListSettings.allowed_guests : null
        " />

      <NumberInput
        label="Guest Limit"
        size="block"
        :can-edit="guestList.allowed_guests !== null"
        :model-value="guestList.allowed_guests === null ? guestListSettings.allowed_guests : guestList.allowed_guests"
        @update:model-value="guestList.allowed_guests = $event" />

      <TextInput
        v-model="guestList.title"
        label="Title" />

      <TextareaInput
        v-model="guestList.notes"
        :min-height="30"
        label="Notes" />

      <hr v-if="guestList.uuid || generateUuid" />
      <SettingToggle
        v-if="getKey(initialGuestList, 'id') === null"
        v-model="generateUuid"
        label="With Link"
        title="Would you like to generate a link for submission to this guest list?" />

      <template v-if="(getKey(initialGuestList, 'id') && guestList.uuid) || generateUuid">
        <div
          v-if="getKey(initialGuestList, 'id')"
          class="flex justify-end">
          <ActionButtonGroup
            inline
            :actions="[
              {
                title: 'Reset Link',
                icon: 'fa-plus fa-regular',
                action: () => {
                  addUuidToGuestList();
                },
              },
              {
                title: 'Make Private',
                icon: 'fa-trash fa-regular',
                action: () => {
                  removeUuidFromGuestList();
                },
              },
            ]"></ActionButtonGroup>
        </div>

        <VRichText
          v-model="guestList.link_description"
          title="This text will be displayed on the Guest list form and visible to anyone who opens the link"
          label="Form Script" />

        <SettingToggle
          v-model="guestList.uuid_can_delete"
          label="Allow deleting on Public Link "
          title="If allowed anyone with the link can delete guests from guest list. If disabled, they can only add and update." />

        <div>
          <h4>Availability</h4>
          <ToggleWithInput
            :model-value="guestList.open_from !== null"
            label="Set When Form Opens"
            title="No value means it will always be open (unless you close it at some point)"
            @update:model-value="guestList.open_from = guestList.open_from === null ? defaultStart : null">
            <template #input>
              <div class="input-section">
                <DateHourSelector
                  v-model:date-time="guestList.open_from"
                  :can-edit="guestList.open_from !== null"
                  required />
              </div>
            </template>
          </ToggleWithInput>
          <ToggleWithInput
            :model-value="guestList.open_to !== null"
            label="Set When Form Closes"
            title="No value means it will never be close (after it opens)"
            @update:model-value="guestList.open_to = guestList.open_to === null ? defaultEnd : null">
            <template #input>
              <div class="input-section">
                <DateHourSelector
                  v-model:date-time="guestList.open_to"
                  :can-edit="guestList.open_to !== null"
                  required />
              </div>
            </template>
          </ToggleWithInput>
        </div>
      </template>
    </div>
    <template
      v-if="getKey(initialGuestList, 'id') && !guestList.uuid"
      #area-in-footer-for-buttons>
      <div class="flex">
        <VButton
          title="Make Public"
          size="lg"
          type="primary"
          @click="addUuidToGuestList()" />
      </div>
    </template>
  </CrudModal>
</template>
