<script setup lang="ts">
import { GroupResource } from '@/types/group';
import { MetaDataModelType, MetaDataResource, SharedMetaDataResource } from '@/types/meta-data';
import { ShowTimeResource } from '@/types/show-time';
import { computed, nextTick, ref, watch } from 'vue';
import MetaDataCRUDModal from '@/components/Models/MetaData/MetaDataCRUDModal.vue';
import MetaData from '@/components/Models/MetaData/MetaData.vue';
import MetaDataReportModal from '@/components/Models/MetaData/MetaDataReportModal.vue';
import ContentList from '@/components/Content/ContentList.vue';
import MetaDataEventReportModal from '@/components/Models/MetaData/MetaDataEventReportModal.vue';
import AuditsSidebar from '@/components/Audits/AuditsSidebar.vue';
import { auditableMetaDataFieldFields, auditableMetaDataFields } from '@/helpers/auditHelper';
import SharedMetaDataInviteSection from '@/components/Models/MetaData/SharedMetaDataInviteSection.vue';
import { storeToRefs } from 'pinia';
import { metaDataStore } from '@/store/meta-data-store';
import { exchangeValuesOfObject, getIndexFromArrayBasedOnId, getKey, sortArrayBy } from '@/util/globals';
import { useEmitStore } from '@/store/EmitStore';
import { metaDataTemplateGalleryRootEmitKey } from '@/components/Models/TemplateGallery/templates/meta-data-templates';
import { getRoute, openRoute } from '@/util/route';
import { getGroupById } from '@/util/group-helpers';

type Props = {
  model: MetaDataModelType;
  modelId: number;
  canEdit: boolean;
  group?: GroupResource | null;
  isTemplate: boolean;
  isVenue: boolean;
  showTimes?: ShowTimeResource[];
  documents?: [];
  isRecurring?: boolean;
  isForChild?: boolean;
  parentModel?: string | null;
  parentModelId?: number | null;
  templateGalleryOpen?: boolean | null;
};

const props = withDefaults(defineProps<Props>(), {
  showTimes: () => [],
  documents: () => [],
  group: null,
  isRecurring: false,
  isForChild: false,
  parentModel: null,
  parentModelId: null,
  templateGalleryOpen: null,
});

const emits = defineEmits<{
  'update:templateGalleryOpen': [arg: boolean];
}>();

const { loading, list } = storeToRefs(metaDataStore());
const { fetchMetaData } = metaDataStore();

let data;

if (!props.isForChild) {
  data = await fetchMetaData(props.model, props.modelId, props.isTemplate);
} else {
  const { data: shared } = await axios.get(`/api/invites/${props.modelId}/shared-meta-data`);

  data = shared.map((d) => {
    return { ...d, fields: sortArrayBy(d.fields, 'order') };
  });
}

const metaDataList = ref(props.isForChild ? [] : (data ?? []));
const sharedMetaData = ref<SharedMetaDataResource[]>(props.isForChild ? (data ?? []) : []);

watch(
  list,
  async () => {
    const data = await fetchMetaData(props.model, props.modelId);

    metaDataList.value = data ?? [];
  },
  { deep: true }
);

const auditsOpen = ref(false);
const reportModalOpen = ref(false);

const selectedMetaData = ref<MetaDataResource | null>(null);

const metaDataParentIds = computed(() => metaDataList.value.map((m) => m.parent_id));

const onDeleteMetaData = async (metaDataId: number) => {
  const index = getIndexFromArrayBasedOnId(metaDataId, metaDataList.value);
  if (index > -1) {
    metaDataList.value.splice(index, 1);
  } else {
    const data = await fetchMetaData(props.model, props.modelId);

    metaDataList.value = data ?? [];
  }
};

const showModal = ref(false);
const openModal = (select: MetaDataResource | null = null) => {
  if (!props.canEdit) return;
  if (select) {
    selectedMetaData.value = select;
  } else {
    selectedMetaData.value = null;
  }
  showModal.value = true;
};

const actions = computed(() => {
  if (!props.canEdit) return [];

  const array = [];

  if (props.isTemplate && props.canEdit && props.templateGalleryOpen !== null) {
    array.push({
      title: 'Template Gallery',
      icon: 'fa-books fa-regular',
      action: async () => {
        emits('update:templateGalleryOpen', false);
        await nextTick();
        emits('update:templateGalleryOpen', true);
      },
    });
  }

  if (!props.isTemplate && !props.isForChild) {
    const subItems = [
      {
        title: 'Audits',
        preIcon: 'fa-history',
        action: async (closeFn: () => void) => {
          auditsOpen.value = false;
          await nextTick();
          auditsOpen.value = true;
          closeFn();
        },
      },
    ];

    if (metaDataList.value.length) {
      subItems.push({
        title: 'Report',
        preIcon: 'fa-download fa-regular',
        action: async (closeFn: () => void) => {
          reportModalOpen.value = false;
          await nextTick();
          reportModalOpen.value = true;
          closeFn();
        },
      });
    }

    if (props.parentModel === 'Group' && props.parentModelId) {
      subItems.push({
        title: 'Edit Templates',
        preIcon: 'fa-cog',
        action: async () => {
          const group = await getGroupById(props.parentModelId);
          openRoute(getRoute('groups.administrator', group.slug) + '#meta-data');
        },
      });
    }

    array.push({
      title: 'Actions',
      icon: 'fa-cog',
      emphasized: true,
      dropdown: subItems,
    });
  }
  if (metaDataList.value.length && props.isTemplate) {
    array.push({
      title: 'Report',
      icon: 'fa-download fa-regular',
      emphasized: true,
      action: async () => {
        reportModalOpen.value = false;
        await nextTick();
        reportModalOpen.value = true;
      },
    });
  }

  if (!props.isForChild) {
    array.push({
      title: props.model === 'Invite' ? 'Add Template' : 'Create',
      icon: 'fa-plus',
      type: 'primary',
      action: () => {
        openModal();
      },
    });
  }

  return array;
});
useEmitStore().$subscribe((mutation, state) => {
  switch (state.item?.key) {
    case 'template-gallery-import':
      const payload = state.item?.payload;
      if (!payload) return;
      if (getKey(payload, 'key') === metaDataTemplateGalleryRootEmitKey) {
        metaDataList.value.push(getKey(payload, 'data'));
      }
      break;
    default:
      break;
  }
});
const getAllowedAuditSidebarFields = () => {
  return [
    { model: 'App\\MetaData', fields: auditableMetaDataFields() },
    { model: 'App\\MetaDataField', fields: auditableMetaDataFieldFields() },
  ];
};
</script>

<template>
  <ContentList
    :loading="loading"
    title="Meta Data"
    :with-back-button="isTemplate"
    :title="isTemplate ? null : 'Meta Data'"
    :under-title="isTemplate ? null : ''"
    :pre-icon="isTemplate ? null : 'fa-table'"
    :height-reduction="0"
    :empty-message="
      metaDataList.length === 0 && sharedMetaData.length === 0 && !loading ? 'No Meta Data Has Been Added' : null
    "
    empty-icon="fa-table"
    content-slot-classes="pt-edge"
    :empty-action="
      canEdit && !props.isForChild
        ? () => {
            openModal();
          }
        : null
    "
    :actions="actions">
    <template v-if="sharedMetaData.length > 0">
      <SharedMetaDataInviteSection
        :invite-id="modelId"
        :can-edit="true"
        :meta-data="sharedMetaData"
        :show-times="showTimes" />
    </template>

    <template v-else>
      <MetaData
        v-for="metaDatum in metaDataList"
        :key="metaDatum.id"
        :can-edit-content="canEdit && !isTemplate"
        :can-edit-form="canEdit && isTemplate"
        :is-display="false"
        :is-template="isTemplate"
        :meta-data="metaDatum"
        :model="model"
        :show-times="showTimes"
        :model-id="modelId"
        :group="group"
        :documents="documents"
        :parent-model="parentModel"
        :parent-model-id="parentModelId"
        @update-data="metaDataList = exchangeValuesOfObject($event, metaDataList)"
        @deleted="onDeleteMetaData"
        @edit="openModal(metaDatum)" />
    </template>
  </ContentList>

  <MetaDataCRUDModal
    v-if="showModal"
    :model-id="modelId"
    :model="model"
    :is-template="isTemplate"
    :show-times="showTimes"
    :is-recurring="isRecurring"
    :parent-model="parentModel"
    :meta-data-parent-ids="metaDataParentIds"
    :parent-model-id="parentModelId"
    :init-meta-data="selectedMetaData"
    @closed="showModal = false"
    @created="metaDataList.push($event)"
    @updated="metaDataList = exchangeValuesOfObject($event, metaDataList)"
    @deleted="onDeleteMetaData" />

  <MetaDataEventReportModal
    v-if="reportModalOpen && !isTemplate && metaDataList.length"
    :is-venue="isVenue"
    :show-times="showTimes"
    :meta-data-list="metaDataList" />

  <MetaDataReportModal
    v-if="reportModalOpen && isTemplate && metaDataList.length"
    :is-venue="isVenue"
    :group-id="group.id" />

  <AuditsSidebar
    v-if="auditsOpen && !isTemplate && model === 'Invite'"
    key="Audits for Meta Data"
    open-on-create
    :can-filter-models="false"
    :url="'/api/audits/invites/' + modelId"
    :queryable-parameters="[
      { name: 'MetaData', id: 'App\\MetaData' },
      { name: 'MetaDataField', id: 'App\\MetaDataField' },
    ]"
    :allowed-fields="getAllowedAuditSidebarFields()"
    title="Audits for Meta Data" />
</template>
